const menu = [
  {
    icon: "sign-btc-alt",
    text: "AHRVO - Buy Sell Panel",
    link: "/crypto",
    newTab: true,
    panel: true,
    subPanel: [
      {
        text: "Dashboard",
        icon: "dashboard",
        link: "/crypto",
      },
      {
        text: "Wallets",
        icon: "wallet-alt",
        link: "/crypto/wallets",
      },
      {
        text: "My Profile",
        icon: "account-setting",
        link: "/crypto/user-profile-regular",
      },
      
    ],
  },
  
];
export default menu;
