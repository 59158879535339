import React, { useState } from "react";
import logo from "../../../../assets/images/Ahrvo_Network_Logo.png";
import arrowDown from "../../../../assets/images/arrow_down.svg";
import sendIcon from "../../../../assets/images/sendIcon.svg";
import recieveIcon from "../../../../assets/images/recieveIcon.svg";
import polygonIcon from "../../../../assets/images/currency-logo/polygon.svg";
import bankIcon from "../../../../assets/images/currency-logo/bank.svg";
import sideArrowIcon from "../../../../assets/images/arrow_right.svg";
import bankCardIcon from "../../../../assets/images/currency-logo/bankCard.svg";
import applePay from "../../../../assets/images/currency-logo/applePay.svg";
import googlePayIcon from "../../../../assets/images/currency-logo/googlePay.svg";
import linkIcon from "../../../../assets/images/linkIcon.svg";
import backIcon from "../../../../assets/images/arrow_left.svg";
import arrowDownCaret from "../../../../assets/images/arrow_down_caret.svg";
import sardineIcon from "../../../../assets/images/currency-logo/sardine.svg";
import qrCode from "../../../../assets/images/qr-codeImg.png";
import { Link } from "react-router-dom";

export const CryptoRecieve = () => {
  const [showProiderBox, setShowProviderBox] = useState(true);

  return (
    <>
      <div className="page-layout">
        <div className="ahrvo-card">
          <div
            className={`backdrop-layer ${showProiderBox ? "hide" : ""}`}
            onClick={() => setShowProviderBox(!showProiderBox)}
          ></div>
          <Link to={`${process.env.PUBLIC_URL}/crypto-home`}>
            <div className="back_navigation">
              <img src={backIcon} alt="" />
            </div>
          </Link>
          <div className="card_header">
            <div className="header_text">
              <div>Polygon</div>
            </div>
          </div>

          <div className="qr-code-box">
            <img src={qrCode} alt="" />
          </div>
          <button type="submit" className=" primary-btn mt-4">
            <span>Copy Address</span>
          </button>
          <p className="recieve-info text-center mt-4">
            This is a <b>Polygon</b> wallet.Only send MATIC or other Polygon
            tokens to this wallet.
          </p>
          <div className="card_footer">
            <div className="footer_logo_img">
              <img src={logo} alt="" />
            </div>
            <span>Secured by </span>
            <span className="footer_logo">Ahrvo</span>
          </div>
        </div>
      </div>
    </>
  );
};
