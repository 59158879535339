import React, { useState, useEffect } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockHeadSub,
  BlockTitle,
  Icon,
} from "../../../components/Component";
import {
  BlockBetween,
  TooltipComponent,
  OverlineTitle,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  RSelect,
} from "../../../components/Component";
import {


  ModalBody,
  Modal,

  Form,
  Badge,
} from "reactstrap";
import Wallet from "../../../components/partials/panel/wallet/Wallet";
import { useForm } from "react-hook-form";
import { cryptoActivityOptions, filterCoin, filterPaymentmethod, filterStatusOptions, orderData, walletTypeOptions, providerOptions } from "../../pre-built/trans-list/TransData";
import { useHistory } from "react-router-dom";



const Wallets = () => {

  let demoWallet = 0;
  const [walletName, setWalletName] = useState('');
  const [walletSets, setWalletSets] = useState([{ "name": "Fiat Account", "balance": "1000" }]);
  const [walletSetsCrypto, setWalletSetsCrypto] = useState([{ "name": "Crypto Account", "balance": "1000" }])
  const [walletType, setWalletType] = useState();
  const [walletProvider, setWalletProvider] = useState();
  const [editCryptoWallet, setEditCryptoWallet] = useState("");
  const [editCryptoWalletName, setEditCryptoWalletName] = useState("");
  const [editFiatWallet, setEditFiatWallet] = useState("");
  const [editFiatWalletName, setEditFiatWalletName] = useState("");


  const history = useHistory();

  useEffect(() => {
    console.log("Wallet type :", walletType)
  }, [walletType])

  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    add: false,
  });
  const [editCryptModal, setEditCryptoModal] = useState({
    add: false,
  });
  const [editFiatModal, setEditFiatModal] = useState({
    add: false,
  });
  const [modalDetail, setModalDetail] = useState(false);
  const [data, setData] = useState("");
  const [detail, setDetail] = useState({});
  const [formData, setFormData] = useState({
    orderType: "Deposit",
    amountBTC: "",
    amountUSD: "",
    balanceBTC: "",
    balanceUSD: "",
    status: "Pending",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");

  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.ref.localeCompare(b.ref));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.ref.localeCompare(a.ref));
      setData([...sortedData]);
    }
  };


  // creating of a wallet codes go here

  const handleWalletCreate = () => {

    if (walletType == "Crypto") {
      setWalletSetsCrypto([
        ...walletSetsCrypto, { name: walletName, balance: "3000" }
      ])
    } else {


      setWalletSets([
        ...walletSets, { name: walletName, balance: "3000" }
      ])
    }

    console.log("walletSetsCrypto: ", walletSetsCrypto)
    console.log("walletSets :", walletSets)
    setModal({
      add: false,
    })
  }

  const handleDeleteWallet = (name) => {
    if (walletType == "Crypto") {
      setWalletSetsCrypto((prevWallets) => prevWallets.filter((walletSetsCrypto) => walletSetsCrypto.name !== name));
    } else {
      setWalletSets((prevWallets) => prevWallets.filter((walletSets) => walletSets.name !== name));
    }
  };

  // function to edit crypto card data
  const handleEditCryptoWallet = (index) => {
    setEditCryptoModal({ add: true })
    console.log("hey", walletSetsCrypto[index])
    setEditCryptoWallet(walletSetsCrypto[index])
  }

  // function to edit fiat card data
  const handleEditFiatWallet = (index) => {
    setEditFiatModal({ add: true })
    console.log("hey", walletSets[index])
    setEditFiatWallet(walletSets[index])
  }

  const saveEditCryptoWallet = () => {
    editCryptoWallet.name = editCryptoWalletName;
    setEditCryptoModal({ add: false })
  }


  const saveEditFiatWallet = () => {
    editFiatWallet.name = editFiatWalletName;
    setEditFiatModal({ add: false })
  }

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      orderType: "Buy",
      amountBTC: "",
      amountUSD: "",
      balanceBTC: "",
      balanceUSD: "",
      status: "Pending",
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ add: false });
    setEditCryptoModal({ add: false })
    setEditFiatModal({ add: false })
    resetForm();
  };

  // submit function to add a new item
  const onFormSubmit = (submitData) => {
    const { amountBTC, amountUSD, balanceUSD, balanceBTC } = submitData;
    let submittedData = {
      id: data.length + 1,
      ref: "YWLX52JG73",
      date: "18/10/2019 12:04 PM",
      desc: formData.orderType === "Profit" ? "Credited " + formData.orderType : formData.orderType + " Funds",
      orderType: formData.orderType,
      amountBTC: amountBTC,
      amountUSD: amountUSD,
      balanceBTC: balanceBTC,
      balanceUSD: balanceUSD,
      status: formData.status,
    };
    setData([submittedData, ...data]);
    resetForm();
    setModal({ add: false });
  };

  // function to change to approve property for an item
  const onApproveClick = (id) => {
    let newData = data;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].status = "Completed";
    setData([...newData]);
  };

  // function to change to reject property for an item
  const onRejectClick = (id) => {
    let newData = data;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].status = "Rejected";
    setData([...newData]);
  };

  // function to load detail data
  const loadDetail = (id) => {
    let index = data.findIndex((item) => item.id === id);
    setDetail(data[index]);
  };

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // function to toggle details modal
  const toggleModalDetail = () => setModalDetail(!modalDetail);

  const { errors, register, handleSubmit } = useForm();




  return (
    <React.Fragment>
      <Head title="Crypto Dashboard"></Head>
      <Content>
        <BlockHead>
          <BlockHeadSub>Account Wallet</BlockHeadSub>
          <div className="nk-block-between-md g-4">
            <BlockHeadContent>
              <BlockTitle tag="h2" className="fw-normal">
                Wallet / Assets
              </BlockTitle>
              <BlockDes>
                <p>Here is the list of your assets / wallets!</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <ul className="nk-block-tools gx-3">
                <li>
                  <UncontrolledDropdown className="opt-menu-md">
                    <DropdownToggle tag="a" className="btn btn-dim btn-outline-light btn-icon">
                      <Icon name="setting"></Icon>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xs" end>
                      <ul className="link-list-plain sm">
                        <li>
                          <DropdownItem tag="a">
                            <span>Display</span>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem tag="a">
                            <span>Show</span>
                          </DropdownItem>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
                <li>
                  <Button color="primary" onClick={() => history.push(`${process.env.PUBLIC_URL}/transfer-method-select`)}>
                    <span>Deposit Methods</span> <Icon name="arrow-long-right"></Icon>
                  </Button>
                </li>
                {/* <li>
                  <Button color="primary" onClick={() => history.push(`${process.env.PUBLIC_URL}/crypto-deposit-method`)}>
                    <span>Deposit Methods</span> <Icon name="arrow-long-right"></Icon>
                  </Button>
                </li> */}
                <li>
                  <Button color="dim" className="btn-outline-light" onClick={() => history.push(`${process.env.PUBLIC_URL}/transfer-method-select-withdraw`)}>
                    <span>Withdrawal Methods</span> <Icon name="arrow-long-right" className="d-none d-sm-inline-block"></Icon>
                  </Button>
                </li>
              </ul>
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block size="lg">
          <BlockHead size="sm">
            <BlockHeadContent>
              <BlockTitle tag="h5">Fiat Accounts</BlockTitle>
            </BlockHeadContent>
          </BlockHead>
          <Row className="g-gs">
            <Col sm="6" lg="6" xl="4">
              <Card className="card-bordered">
                <Wallet
                  className="is-default"
                  icon="sign-usd"
                  title="USD Account"
                  firstAmount="12,495.90"
                  firstCurrency="USD"
                  secondAmount="12,495.90"
                  secondCurrency="USD"
                  SendPageLink={`${process.env.PUBLIC_URL}/fiat-home`}
                  RecievePageLink={`${process.env.PUBLIC_URL}/fiat-recieve`}
                  BuyPageLink={`${process.env.PUBLIC_URL}/fiat-buy`}
                  SwapPageLink={`${process.env.PUBLIC_URL}/fiat-swap-form`}
                />
              </Card>
            </Col>
            {
              walletSets.map((walletData, index) => (
                <Col sm="6" lg="6" xl="4">
                  <Card className="card-bordered">
                    <Wallet
                      id={index}
                      className="is-default"
                      icon="sign-usd"
                      title={walletData.name}
                      firstAmount="12,495.90"
                      firstCurrency="USD"
                      secondAmount="12,495.90"
                      secondCurrency="USD"
                      SendPageLink={`${process.env.PUBLIC_URL}/fiat-home`}
                      RecievePageLink={`${process.env.PUBLIC_URL}/fiat-recieve`}
                      BuyPageLink={`${process.env.PUBLIC_URL}/fiat-buy`}
                      SwapPageLink={`${process.env.PUBLIC_URL}/fiat-swap-form`}
                      onDelete={(index) => handleDeleteWallet(index)}
                      onEdit={() => handleEditFiatWallet(index)}
                    />
                  </Card>
                </Col>

              ))
            }
            <Col sm="6" lg="6" xl="4">
              <Card className="card-bordered dashed h-100">
                <div className="nk-wgw-add">
                  <div className="nk-wgw-inner" onClick={() => setModal({ add: true })}>
                    <a href="#">
                      <div className="add-icon">
                        <em className="icon ni ni-plus"></em>
                      </div>
                      <h6 className="title">Add New Wallet</h6>
                    </a>
                    <span className="sub-text">You can add your more wallet in your account to manage separetly.</span>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Block>
        <Block>
          <BlockHead size="sm">
            <BlockHeadContent>
              <BlockTitle tag="h5">Crypto Accounts</BlockTitle>
            </BlockHeadContent>
          </BlockHead>
          <Row className="g-gs">
            <Col sm="6" lg="6" xl="4">
              <Card className="card-bordered is-dark">
                <Wallet
                  className="is-default"
                  icon="sign-kobo"
                  title="NioWallet"
                  firstAmount="40.509505"
                  firstCurrency="NIO"
                  secondAmount="8,924.63"
                  secondCurrency="USD"
                  SendPageLink={`${process.env.PUBLIC_URL}/crypto-home`}
                  RecievePageLink={`${process.env.PUBLIC_URL}/crypto-recieve`}
                  BuyPageLink={`${process.env.PUBLIC_URL}/crypto-buy`}
                  SwapPageLink={`${process.env.PUBLIC_URL}/crypto-swap`}
                />
              </Card>
            </Col>
            {walletSetsCrypto.map((walletData, index) => (
              <Col sm="6" lg="6" xl="4">
                <Card className="card-bordered">
                  <Wallet
                    id={index}
                    className="is-default"
                    icon="sign-kobo"
                    title={walletData.name}
                    firstAmount="40.509505"
                    firstCurrency="NIO"
                    secondAmount="8,924.63"
                    secondCurrency="USD"
                    SendPageLink={`${process.env.PUBLIC_URL}/crypto-home`}
                    RecievePageLink={`${process.env.PUBLIC_URL}/crypto-recieve`}
                    BuyPageLink={`${process.env.PUBLIC_URL}/crypto-buy`}
                    SwapPageLink={`${process.env.PUBLIC_URL}/crypto-swap`}
                    onDelete={() => handleDeleteWallet(walletData.name)}
                    onEdit={() => handleEditCryptoWallet(index)}
                  />
                </Card>
              </Col>
            ))
            }
          </Row>
        </Block>

      </Content>
      <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <a
            href="#close"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Add Wallet</h5>
            <Form className="mt-4" onSubmit={handleSubmit(onFormSubmit)} noValidate>
              <Row className="g-gs">
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Wallet Name</label>
                    <input
                      type="text"
                      name="walletName"
                      // defaultValue={formData.amountBTC}
                      placeholder="Wallet name"
                      className="form-control"
                      onChange={(e) => setWalletName(e.target.value)}
                    // ref={register({ required: "This field is required" })}
                    />
                    {errors.amountBTC && <span className="invalid">{errors.amountBTC.message}</span>}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Wallet Type</label>
                    <div className="form-control-wrap">
                      <RSelect
                        options={walletTypeOptions}
                        defaultValue={[{ value: "select", label: "Select" }]}
                        onChange={(e) => setWalletType(e.value)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <OverlineTitle className="pt-4"> Amount </OverlineTitle> */}
              <hr className="hr mt-2 border-light" />
              <Row className="g-gs">
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Provider</label>
                    <div className="form-control-wrap">
                      <RSelect
                        options={providerOptions}
                        defaultValue={[{ value: "select", label: "Select" }]}
                        onChange={(e) => setFormData({ ...formData, status: e.value })}
                      />
                    </div>
                  </div>
                </Col>
                {/* <Col md="6">
                  <div className="form-group">
                    <label className="form-label">USD</label>
                    <input
                      type="number"
                      name="amountUSD"
                      defaultValue={formData.amountUSD}
                      className="form-control"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.amountUSD && <span className="invalid">{errors.amountUSD.message}</span>}
                  </div>
                </Col> */}
              </Row>
              {/* <OverlineTitle className="pt-4"> Balance </OverlineTitle> */}
              <hr className="hr mt-2 border-light" />
              <Row className="gy-4">
                {/* <Col md="6">
                  <div className="form-group">
                    <label className="form-label">BTC</label>
                    <input
                      type="number"
                      name="balanceBTC"
                      defaultValue={formData.balanceBTC}
                      className="form-control"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.balanceBTC && <span className="invalid">{errors.balanceBTC.message}</span>}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">USD</label>
                    <input
                      type="number"
                      name="balanceUSD"
                      defaultValue={formData.balanceUSD}
                      className="form-control"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.balanceUSD && <span className="invalid">{errors.balanceUSD.message}</span>}
                  </div>
                </Col> */}
                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button color="primary" size="md" onClick={() => handleWalletCreate()} >
                        Add Wallet
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Form>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={editCryptModal.add} toggle={() => setEditCryptoModal({ add: false })} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <a
            href="#close"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Add Wallet</h5>
            <Form className="mt-4" onSubmit={handleSubmit(onFormSubmit)} noValidate>
              <Row className="g-gs">
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Wallet Name</label>
                    <input
                      type="text"
                      name="walletName"
                      defaultValue={editCryptoWallet.name}
                      placeholder="Wallet name"
                      className="form-control"
                      onChange={(e) => setEditCryptoWalletName(e.target.value)}
                    // ref={register({ required: "This field is required" })}
                    />
                    {errors.amountBTC && <span className="invalid">{errors.amountBTC.message}</span>}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Wallet Type</label>
                    <div className="form-control-wrap">
                      <RSelect
                        options={walletTypeOptions}
                        defaultValue={[{ value: "select", label: "Select" }]}
                        onChange={(e) => setWalletType(e.value)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <hr className="hr mt-2 border-light" />
              <Row className="g-gs">
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Provider</label>
                    <div className="form-control-wrap">
                      <RSelect
                        options={providerOptions}
                        defaultValue={[{ value: "select", label: "Select" }]}
                        onChange={(e) => setFormData({ ...formData, status: e.value })}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <hr className="hr mt-2 border-light" />
              <Row className="gy-4">
                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button color="primary" size="md" onClick={() => saveEditCryptoWallet()} >
                        Add Wallet
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>

            </Form>
          </div>
        </ModalBody>
      </Modal>


      <Modal isOpen={editFiatModal.add} toggle={() => setEditFiatModal({ add: false })} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <a
            href="#close"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Add Wallet</h5>
            <Form className="mt-4" onSubmit={handleSubmit(onFormSubmit)} noValidate>
              <Row className="g-gs">
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Wallet Name</label>
                    <input
                      type="text"
                      name="walletName"
                      defaultValue={editFiatWallet.name}
                      placeholder="Wallet name"
                      className="form-control"
                      onChange={(e) => setEditFiatWalletName(e.target.value)}
                    // ref={register({ required: "This field is required" })}
                    />
                    {errors.amountBTC && <span className="invalid">{errors.amountBTC.message}</span>}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Wallet Type</label>
                    <div className="form-control-wrap">
                      <RSelect
                        options={walletTypeOptions}
                        defaultValue={[{ value: "select", label: "Select" }]}
                        onChange={(e) => setWalletType(e.value)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <hr className="hr mt-2 border-light" />
              <Row className="g-gs">
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Provider</label>
                    <div className="form-control-wrap">
                      <RSelect
                        options={providerOptions}
                        defaultValue={[{ value: "select", label: "Select" }]}
                        onChange={(e) => setFormData({ ...formData, status: e.value })}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <hr className="hr mt-2 border-light" />
              <Row className="gy-4">
                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button color="primary" size="md" onClick={() => saveEditFiatWallet()} >
                        Add Wallet
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>

            </Form>
          </div>
        </ModalBody>
      </Modal>

    </React.Fragment>
  );
};

export default Wallets;
