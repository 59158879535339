import React, { useState } from 'react'
import arrowDownCaret from "../../../assets/images/arrow_down_caret.svg";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function DepositMethodSelect() {
  const [showProiderBox, setShowProviderBox] = useState(false);

  return (
    <div className="page-layout">
      <div className="ahrvo-card">
        <div
          className={`provider-selection-box ${showProiderBox ? "hide-selection-box" : ""
            }`}
        >
          <div className="provider-selection-header">
            <div className="provider-heading">Choose a currency</div>
            <div
              className="close-down-arrow"
              onClick={() => setShowProviderBox(!showProiderBox)}
            >
              <img src={arrowDownCaret} alt="" />
            </div>
          </div>
          <div className="provider-options">
            <Link
              className="provider-option"
              to={`${process.env.PUBLIC_URL}/deposit-method-crypto`}
            >
              <div className="provider-text">Crypto</div>
            </Link>
            <Link
              className="provider-option"
              to={`${process.env.PUBLIC_URL}/fiat-home`}
            >
              <div className="provider-text">Fiat</div>
            </Link>
            {/* <div className="provider-option">
              <div className="provider-option-left">
                <div className="provider-icon">
                  <img src={SGVeteriesLogo} alt="" />
                </div>
              </div>
              <div className="provider-text">$3,000 daily limit</div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DepositMethodSelect
