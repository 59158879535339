import React from "react";
import { Icon } from "../../../components/Component";
import {
  DataTableRow,
  DataTableItem,
  TooltipComponent,
} from "../../../components/Component";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  ModalBody,
  Modal,
  DropdownItem,
  Form,
  Badge,
} from "reactstrap";

export const cryptoTransData = [
  {
    id: 1,
    transactionId: "#TNX67234",
    referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
    transactionFee: "0.000002",
    paymentMethod: "CoinPayments",
    paymentFrom: "1xA058106537340385c87d264f93",
    paymentTo: "1x0385c87d264A05810653734f93",
    transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
    ref: "YWLX52JG73",
    orderType: "Deposit",
    desc: "Deposited Funds",
    date: "18/10/2019 12:04 PM",
    amountBTC: "0.010201",
    amountUSD: "1290.49",
    balanceBTC: "1.30910201",
    balanceUSD: "101290.49",
    status: "Completed",
  },
  {
    id: 2,
    transactionId: "#TNX67234",
    referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
    transactionFee: "0.000002",
    paymentMethod: "CoinPayments",
    paymentFrom: "1xA058106537340385c87d264f93",
    paymentTo: "1x0385c87d264A05810653734f93",
    transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
    ref: "YWLX52JG74",
    orderType: "Withdrawal",
    desc: "Withdrawal Funds",
    date: "18/10/2019 12:04 PM",
    amountBTC: "0.010201",
    amountUSD: "1290.49",
    balanceBTC: "1.30910201",
    balanceUSD: "101290.49",
    status: "Upcoming",
  },
  {
    id: 3,
    transactionId: "#TNX67234",
    referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
    transactionFee: "0.000002",
    paymentMethod: "CoinPayments",
    paymentFrom: "1xA058106537340385c87d264f93",
    paymentTo: "1x0385c87d264A05810653734f93",
    transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
    ref: "YWLX52JG75",
    orderType: "Profit",
    desc: "Credited Profits",
    date: "18/10/2019 12:04 PM",
    amountBTC: "0.010201",
    amountUSD: "1290.49",
    balanceBTC: "1.30910201",
    balanceUSD: "101290.49",
    status: "Pending",
  },
  {
    id: 4,
    transactionId: "#TNX67234",
    referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
    transactionFee: "0.000002",
    paymentMethod: "CoinPayments",
    paymentFrom: "1xA058106537340385c87d264f93",
    paymentTo: "1x0385c87d264A05810653734f93",
    transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
    ref: "YWLX52JG76",
    orderType: "Withdrawal",
    desc: "Withdrawal Funds",
    date: "18/10/2019 12:04 PM",
    amountBTC: "0.010201",
    amountUSD: "1290.49",
    balanceBTC: "1.30910201",
    balanceUSD: "101290.49",
    status: "Rejected",
  },
  {
    id: 5,
    transactionId: "#TNX67234",
    referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
    transactionFee: "0.000002",
    paymentMethod: "CoinPayments",
    paymentFrom: "1xA058106537340385c87d264f93",
    paymentTo: "1x0385c87d264A05810653734f93",
    transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
    ref: "YWLX52JG77",
    orderType: "Deposit",
    desc: "Deposited Funds",
    date: "18/10/2019 12:04 PM",
    amountBTC: "0.010201",
    amountUSD: "1290.49",
    balanceBTC: "1.30910201",
    balanceUSD: "101290.49",
    status: "Pending",
  },
  {
    id: 6,
    transactionId: "#TNX67234",
    referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
    transactionFee: "0.000002",
    paymentMethod: "CoinPayments",
    paymentFrom: "1xA058106537340385c87d264f93",
    paymentTo: "1x0385c87d264A05810653734f93",
    transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
    ref: "YWLX52JG78",
    orderType: "Withdrawal",
    desc: "Withdrawal Funds",
    date: "18/10/2019 12:04 PM",
    amountBTC: "0.010201",
    amountUSD: "1290.49",
    balanceBTC: "1.30910201",
    balanceUSD: "101290.49",
    status: "Upcoming",
  },
  {
    id: 7,
    transactionId: "#TNX67234",
    referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
    transactionFee: "0.000002",
    paymentMethod: "CoinPayments",
    paymentFrom: "1xA058106537340385c87d264f93",
    paymentTo: "1x0385c87d264A05810653734f93",
    transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
    ref: "YWLX52JG79",
    orderType: "Profit",
    desc: "Credited Profits",
    date: "18/10/2019 12:04 PM",
    amountBTC: "0.010201",
    amountUSD: "1290.49",
    balanceBTC: "1.30910201",
    balanceUSD: "101290.49",
    status: "Pending",
  },
  {
    id: 8,
    transactionId: "#TNX67234",
    referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
    transactionFee: "0.000002",
    paymentMethod: "CoinPayments",
    paymentFrom: "1xA058106537340385c87d264f93",
    paymentTo: "1x0385c87d264A05810653734f93",
    transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
    ref: "YWLX52JG80",
    orderType: "Withdrawal",
    desc: "Withdrawal Funds",
    date: "18/10/2019 12:04 PM",
    amountBTC: "0.010201",
    amountUSD: "1290.49",
    balanceBTC: "1.30910201",
    balanceUSD: "101290.49",
    status: "Rejected",
  },
  {
    id: 9,
    transactionId: "#TNX67234",
    referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
    transactionFee: "0.000002",
    paymentMethod: "CoinPayments",
    paymentFrom: "1xA058106537340385c87d264f93",
    paymentTo: "1x0385c87d264A05810653734f93",
    transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
    ref: "YWLX52JG81",
    orderType: "Deposit",
    desc: "Deposited Funds",
    date: "18/10/2019 12:04 PM",
    amountBTC: "0.010201",
    amountUSD: "1290.49",
    balanceBTC: "1.30910201",
    balanceUSD: "101290.49",
    status: "Pending",
  },
  {
    id: 10,
    transactionId: "#TNX67235",
    referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
    transactionFee: "0.000025",
    paymentMethod: "CoinPayments",
    paymentFrom: "1xA058106537340385c87d264f93",
    paymentTo: "1x0385c87d264A05810653734f93",
    transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
    ref: "YWLX52JG82",
    orderType: "Deposit",
    desc: "Deposited Funds",
    date: "18/10/2019 12:04 PM",
    amountBTC: "0.010201",
    amountUSD: "1290.49",
    balanceBTC: "1.30910201",
    balanceUSD: "101290.49",
    status: "Rejected",
  },
  {
    id: 11,
    transactionId: "#TNX67237",
    referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
    transactionFee: "0.000025",
    paymentMethod: "CoinPayments",
    paymentFrom: "1xA058106537340385c87d264f93",
    paymentTo: "1x0385c87d264A05810653734f93",
    transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
    ref: "YWLX52JG83",
    orderType: "Withdraw",
    desc: "Withdrawal Funds",
    date: "18/10/2019 12:04 PM",
    amountBTC: "0.010201",
    amountUSD: "1290.49",
    balanceBTC: "1.30910201",
    balanceUSD: "101290.49",
    status: "Rejected",
  },
  {
    id: 12,
    transactionId: "#TNX67238",
    referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
    transactionFee: "0.000025",
    paymentMethod: "CoinPayments",
    paymentFrom: "1xA058106537340385c87d264f93",
    paymentTo: "1x0385c87d264A05810653734f93",
    transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
    ref: "YWLX52JG84",
    orderType: "Withdraw",
    desc: "Withdrawal Funds",
    date: "18/10/2019 12:04 PM",
    amountBTC: "0.010201",
    amountUSD: "1290.49",
    balanceBTC: "1.30910201",
    balanceUSD: "101290.49",
    status: "Upcoming",
  },
  {
    id: 13,
    transactionId: "#TNX67239",
    referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
    transactionFee: "0.000025",
    paymentMethod: "CoinPayments",
    paymentFrom: "1xA058106537340385c87d264f93",
    paymentTo: "1x0385c87d264A05810653734f93",
    transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
    ref: "YWLX52JG85",
    orderType: "Profits",
    desc: "Credited Profit",
    date: "18/10/2019 12:04 PM",
    amountBTC: "0.010201",
    amountUSD: "1290.49",
    balanceBTC: "1.30910201",
    balanceUSD: "101290.49",
    status: "Rejected",
  },
  {
    id: 14,
    transactionId: "#TNX67240",
    referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
    transactionFee: "0.000855",
    paymentMethod: "CoinPayments",
    paymentFrom: "1xA058106537340385c87d264f93",
    paymentTo: "1x0385c87d264A05810653734f93",
    transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
    ref: "YWLX52JG86",
    orderType: "Profits",
    desc: "Credited Profit",
    date: "18/10/2019 12:04 PM",
    amountBTC: "0.010201",
    amountUSD: "1290.49",
    balanceBTC: "1.30910201",
    balanceUSD: "101290.49",
    status: "Completed",
  },
  {
    id: 15,
    transactionId: "#TNX67241",
    referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
    transactionFee: "0.000855",
    paymentMethod: "CoinPayments",
    paymentFrom: "1xA058106537340385c87d264f93",
    paymentTo: "1x0385c87d264A05810653734f93",
    transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
    ref: "YWLX52JG87",
    orderType: "Profits",
    desc: "Credited Profit",
    date: "18/10/2019 12:04 PM",
    amountBTC: "0.010201",
    amountUSD: "1290.49",
    balanceBTC: "1.30910201",
    balanceUSD: "101290.49",
    status: "Completed",
  },
  {
    id: 16,
    transactionId: "#TNX67242",
    referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
    transactionFee: "0.000855",
    paymentMethod: "CoinPayments",
    paymentFrom: "1xA058106537340385c87d264f93",
    paymentTo: "1x0385c87d264A05810653734f93",
    transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
    ref: "YWLX52JG88",
    orderType: "Profits",
    desc: "Credited Profit",
    date: "18/10/2019 12:04 PM",
    amountBTC: "0.010201",
    amountUSD: "1290.49",
    balanceBTC: "1.30910201",
    balanceUSD: "101290.49",
    status: "Rejected",
  },
  {
    id: 17,
    transactionId: "#TNX67250",
    referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
    transactionFee: "0.000855",
    paymentMethod: "CoinPayments",
    paymentFrom: "1xA058106537340385c87d264f93",
    paymentTo: "1x0385c87d264A05810653734f93",
    transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
    ref: "YWLX52JG89",
    orderType: "Profits",
    desc: "Credited Profit",
    date: "18/10/2019 12:04 PM",
    amountBTC: "0.010201",
    amountUSD: "1290.49",
    balanceBTC: "1.30910201",
    balanceUSD: "101290.49",
    status: "Upcoming",
  },
  {
    id: 18,
    transactionId: "#TNX67262",
    referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
    transactionFee: "0.000855",
    paymentMethod: "CoinPayments",
    paymentFrom: "1xA058106537340385c87d264f93",
    paymentTo: "1x0385c87d264A05810653734f93",
    transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
    ref: "YWLX52JG90",
    orderType: "Withdrawal",
    desc: "Withdraw funds",
    date: "18/10/2019 12:04 PM",
    amountBTC: "0.010201",
    amountUSD: "1290.49",
    balanceBTC: "1.30910201",
    balanceUSD: "101290.49",
    status: "Pending",
  },
  {
    id: 19,
    transactionId: "#TNX67259",
    referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
    transactionFee: "0.000855",
    paymentMethod: "CoinPayments",
    paymentFrom: "1xA058106537340385c87d264f93",
    paymentTo: "1x0385c87d264A05810653734f93",
    transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
    ref: "YWLX52JG91",
    orderType: "Profits",
    desc: "Credited Profit",
    date: "18/10/2019 12:04 PM",
    amountBTC: "0.010201",
    amountUSD: "1290.49",
    balanceBTC: "1.30910201",
    balanceUSD: "101290.49",
    status: "Upcoming",
  },
  {
    id: 20,
    transactionId: "#TNX67277",
    referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
    transactionFee: "0.000855",
    paymentMethod: "CoinPayments",
    paymentFrom: "1xA058106537340385c87d264f93",
    paymentTo: "1x0385c87d264A05810653734f93",
    transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
    ref: "YWLX52JG93",
    orderType: "Profits",
    desc: "Credited Profit",
    date: "18/10/2019 12:04 PM",
    amountBTC: "0.010201",
    amountUSD: "1290.49",
    balanceBTC: "1.30910201",
    balanceUSD: "101290.49",
    status: "Rejected",
  },
];

// function to change to approve property for an item
const onApproveClick = (id) => {
  let newData = cryptoTransData;
  let index = newData.findIndex((item) => item.id === id);
  newData[index].status = "Completed";
//   setData([...newData]);
};

// function to change to reject property for an item
const onRejectClick = (id) => {
    let newData = cryptoTransData;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].status = "Rejected";
    // setData([...newData]);
  };

// function to load detail data
const loadDetail = (id) => {
let index = cryptoTransData.findIndex((item) => item.id === id);
// setDetail(data[index]);
};


export const cryptoColumnData = [
  {
    name: "Details",
    selector: (row) => row.desc,
    compact: true,
    grow: 2,
    style: { "padding-left": "24px !important" },
    className: "Hello",
    cell: (row) => (
      <>
        <DataTableRow>
          <div className="nk-tnx-type">
            <div
              className={`nk-tnx-type-icon bg-${
                row.status === "Completed"
                  ? "success-dim text-success"
                  : row.status === "Upcoming"
                  ? "warning-dim text-warning"
                  : row.status === "Pending"
                  ? "info-dim text-info"
                  : "danger-dim text-danger"
              }`}
            >
              <Icon name="arrow-up-right"></Icon>
            </div>
            <div className="nk-tnx-type-text">
              <span className="tb-lead font-w-500">{row.desc}</span>
              <span className="tb-date">{row.date}</span>
            </div>
          </div>
        </DataTableRow>
      </>
    ),
    sortable: true,
  },
  {
    name: "Source",
    selector: (row) => row.balance,
    minWidth: "0px",
    cell: (row) => (
      <DataTableRow size="lg">
        <span className="tb-lead-sub">Using PayPal Account</span>
        <span className="tb-sub">mypay*****com</span>
      </DataTableRow>
    ),
    sortable: true,
    hide: 1440,
  },
  {
    name: "Order",
    selector: (row) => row.phone,
    sortable: true,
    cell: (row) => (
      <DataTableRow size="lg">
        <span className="tb-lead-sub">{row.ref}</span>
        <Badge
          className="badge-dot"
          color={
            row.status === "Completed"
              ? "success"
              : row.status === "Upcoming"
              ? "warning"
              : row.status === "Pending"
              ? "info"
              : "danger"
          }
        >
          {row.orderType}
        </Badge>
      </DataTableRow>
    ),
  },
  {
    name: "Amount",
    selector: (row) => row.amountBTC,
    sortable: true,
    minWidth: "170px",
    hide: "md",
    cell: (row) => (
      <DataTableRow className="text-end">
        <span className="tb-amount">
          + {row.amountBTC} <span>BTC</span>
        </span>
        <span className="tb-amount-sm">{row.amountUSD} USD</span>
      </DataTableRow>
    ),
  },
  {
    name: "Balance",
    selector: (row) => row.amountUSD,
    sortable: true,
    cell: (row) => (
      <DataTableRow className="text-end">
        <span className="tb-amount">
          + {row.amountBTC} <span>BTC</span>
        </span>
        <span className="tb-amount-sm">{row.amountUSD} USD</span>
      </DataTableRow>
    ),
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
    hide: "sm",
    cell: (row) => (
      <DataTableRow className="nk-tb-col-status">
        <div
          className={`dot dot-${
            row.status === "Completed"
              ? "success"
              : row.status === "Upcoming"
              ? "warning"
              : row.status === "Pending"
              ? "info"
              : "danger"
          } d-md-none`}
        ></div>
        <Badge
          className="badge-sm badge-dim d-none d-md-inline-flex"
          color={`outline-${
            row.status === "Completed"
              ? "success"
              : row.status === "Upcoming"
              ? "warning"
              : row.status === "Pending"
              ? "info"
              : "danger"
          }`}
        >
          {row.status}
        </Badge>
      </DataTableRow>
    ),
  },
  {
    name: " ",
    // selector: (row) => row.amountUSD,
    // sortable: true,
    cell: (row) => (
      <DataTableRow className="nk-tb-col-tools">
        <ul className="nk-tb-actions gx-1">
          <li
            className="nk-tb-action-"
            // onClick={() => {
            //     loadDetail(item.id);
            //     toggleModalDetail();
            // }}
          >
            <TooltipComponent
              tag="a"
              containerClassName="bg-white btn btn-sm btn-outline-light btn-icon btn-tooltip"
              id={row.ref + "details"}
              icon="eye"
              direction="top"
              text="Details"
            />
          </li>
          {row.status !== "Completed" && row.status !== "Rejected" && (
            <React.Fragment>
              <li className="nk-tb-action-">
                <TooltipComponent
                  tag="a"
                  containerClassName="bg-white btn btn-sm btn-outline-light btn-icon btn-tooltip"
                  id={row.ref + "approve"}
                  icon="done"
                  direction="top"
                  text="approve"
                />
              </li>
              <li className="nk-tb-action-">
                <TooltipComponent
                  tag="a"
                  containerClassName="bg-white btn btn-sm btn-outline-light btn-icon btn-tooltip"
                  id={row.ref + "reject"}
                  icon="cross-round"
                  direction="top"
                  text="Reject"
                />
              </li>
            </React.Fragment>
          )}
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle bg-white btn btn-sm btn-outline-light btn-icon"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  {row.status !== "Completed" && row.status !== "Rejected" && (
                    <React.Fragment>
                      <li onClick={() => onApproveClick(row.id)}>
                        <DropdownItem
                          tag="a"
                          href="#approve"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          <Icon name="done"></Icon>
                          <span>Approve</span>
                        </DropdownItem>
                      </li>
                      <li onClick={() => onRejectClick(row.id)}>
                        <DropdownItem
                          tag="a"
                          href="#reject"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          <Icon name="cross-round"></Icon>
                          <span>Reject</span>
                        </DropdownItem>
                      </li>
                    </React.Fragment>
                  )}
                  <li
                    onClick={() => {
                      loadDetail(row.id);
                    //   toggleModalDetail();
                    }}
                  >
                    <DropdownItem
                      tag="a"
                      href="#details"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <Icon name="eye"></Icon>
                      <span>Details</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </DataTableRow>
    ),
  },
];
