import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import logo from "../../../../assets/images/Ahrvo_Network_Logo.png";
import backIcon from "../../../../assets/images/arrow_left.svg";

function FiatSendSameReview() {
    const [checked, setChecked] = useState(false);
    function handleChange(e) {
        setChecked(e.target.checked);
    }
    return (
        <div className='page-layout'>
            <div className="ahrvo-card crypto-review">
                <Link to={`${process.env.PUBLIC_URL}/fiat-send-same`}>
                    <div className="back_navigation">
                        <img src={backIcon} alt="" />
                    </div>
                </Link>
                <div className="card_header">
                    <div className="header_text">
                        {/* <h5>Who are you sending money to?</h5> */}
                    </div>
                </div>
                <h6 className="text-center mb-4">Review details of your transfer</h6>
                <div className='note'>Note our team will only manually review your documents once you have paid for this transfer. Once confirmed, pay for this transfer right away.</div>
                <div className='details-parent'>
                    <div className='transfer-details'>
                        <ul>
                            <li className='transfer-heading mb-1'>
                                <span>Transfer details</span>
                                <span>Edit</span>
                            </li>
                            <li>
                                <span>You send exactly</span>
                                <span className='money'>1,000 USD</span>
                            </li>
                            <li className='mb-1'>
                                <span>Total fees(included)</span>
                                <span>8.87 USD</span>
                            </li>
                            <li className='mb-1'>
                                <span>Total amount we'll convert</span>
                                <span>991.13 USD</span>
                            </li>
                            <li className='mb-1'>
                                <span className='guaranty'>Guaranteed rate (23 hours)</span>
                                <span>82.3695</span>
                            </li>
                            <li>
                                <span>Rahul gets</span>
                                <span className='money'>81,638.88 INR</span>
                            </li>
                        </ul>
                    </div>
                    <div className='recipient-details'>
                        <ul>
                            <li className='transfer-heading mb-1'>
                                <span>Recipient details</span>
                                <span>Change</span>
                            </li>
                            <li className='mb-1'>
                                <span>Name</span>
                                <span>Rahul Kumar Ojha</span>
                            </li>
                            <li className='mb-1'>
                                <span>Email</span>
                                <span>rahulkumar82352@gmail.com</span>
                            </li>
                            <li>
                                <span>UPI ID</span>
                                <span>6376050683@ybl</span>
                            </li>
                        </ul>
                    </div>
                    <div className='recipient-details'>
                        <ul>
                            <li className='transfer-heading mb-1'>
                                <span>Schedule details</span>
                                <span>Edit</span>
                            </li>
                            <li className='mb-1'>
                                <span>Sending</span>
                                <span>Now</span>
                            </li>
                            <li className='mb-1'>
                                <span>Should arrive</span>
                                <span>by Wednesday, 7 June</span>
                            </li>
                            <li>
                                <span>Repeats</span>
                                <span>Never</span>
                            </li>
                        </ul>
                    </div>
                    <div className='recipient-details'>
                        <ul>
                            <li className='transfer-heading mb-1'>
                                <span>Reference for Rahul (optional)</span>
                                <span>Add</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='message'>You can cancel for a full refund within 30 minutes of payment, unless the funds have been picked up or deposited. Make sure you're sending money to someone you know and trust, and that their information is correct. Fraudulent transactions may result in the loss of money with no recourse.</div>
                <div className='checkbox-parent'>
                    <input value="test" type="checkbox" onChange={handleChange} />
                    <span>I accept the <strong>Terms of Use</strong> and <strong>Privacy policy</strong></span>
                </div>
                <Link to={`${process.env.PUBLIC_URL}/fiat-send-same-verify`} className="primary-button-wallet mt-5">Confirm and continue</Link>
                <div className="card_footer">
                    <div className="footer_logo_img">
                        <img src={logo} alt="" />
                    </div>
                    <span>Secured by </span>
                    <span className="footer_logo">Ahrvo</span>
                </div>
            </div>
        </div>
    )
}

export default FiatSendSameReview
