import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import backIcon from "../../../../assets/images/arrow_left.svg";
import logo from "../../../../assets/images/Ahrvo_Network_Logo.png";
import creditCard from "../../../../assets/images/creditcard-colored.svg";
import impsIcon from "../../../../assets/images/imps-colored.svg";
import ellipsis from "../../../../assets/images/ellipsis.svg";
import walletIcon from "../../../../assets/images/wallet-svgrepo-com.svg";
import editIcon from "../../../../assets/images/edit-svgrepo-com.svg";
import { useHistory } from "react-router-dom";

import arrowDownCaret from "../../../../assets/images/arrow_down_caret.svg";

import Select from "react-select";


export const CryptoSend = () => {
    const history = useHistory();

    const [options, setOptions] = React.useState([]);
    const [showFiatCurrencySelect, setShowFiatCurrencySelect] = useState(true);
    const [showCryptoCurrencySelect, setShowCryptoCurrencySelect] =
        useState(true);
    const [selectedCryptoCurrency, setSelectedCryptoCurrency] =
        useState("Select");


    function handleCryptoChange(e) {
        setShowCryptoCurrencySelect(!showCryptoCurrencySelect);
        setSelectedCryptoCurrency(e);
    }

    const filterOption = (option, inputValue) => {
        return option?.data.name.toLowerCase().includes(inputValue.toLowerCase());
    };

    useEffect(() => {
        async function FetchData() {
            fetch(
                `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=1000`,
                { mode: "cors" }
            )
                .then((res) => res.json())
                .then((result) => {
                    setOptions(result);
                    // console.log(Object.keys(result[0].currencies)[0])
                    // console.log(result);
                });
        }
        FetchData();
    }, [0]);

    const customStyles = {
        option: (provided) => ({
            ...provided,
            color: "black",
            padding: 5,
        }),
    };

    return (
        <>
            <div className="page-layout">
                <div className="ahrvo-card">
                    <Link to={`${process.env.PUBLIC_URL}/crypto-home`}>
                        <div className="back_navigation">
                            <img src={backIcon} alt="" />
                        </div>
                    </Link>
                    <div className="card_header">
                        <div className="header_text">
                            <div>Polygon</div>
                        </div>
                    </div>
                    {/* <div className="d-flex justify-content-center mb-2">
                        <Link className="btn btn-light" to={`${process.env.PUBLIC_URL}/crypto-send-same-currency`}>Same currency</Link>
                    </div> */}
                    <div className="send-money-area mt-3">
                        <input
                            className="balance-input"
                            type="number"
                            placeholder="$0"
                        />
                    </div>
                    <div className="basic-btn">Send all</div>
                    <div className="send-balance-form-box">
                        <div className="send-balance-form-group">
                            <label htmlFor="">Pay with</label>
                            <div className="currency_select_box">
                                <div
                                    className="demo-box"
                                    onClick={() =>
                                        setShowCryptoCurrencySelect(!showCryptoCurrencySelect)
                                    }
                                ></div>
                                {/* <button  onClick={() => setShowFiatCurrencySelect(!showFiatCurrencySelect)}>Click me</button> */}
                                <img src={selectedCryptoCurrency.image} alt="" />
                                <select name="" id="">
                                    <option value="INR">{selectedCryptoCurrency.name || "Select"}</option>
                                </select>
                            </div>
                        </div>
                        <div className="send-balance-form-group">
                            <label htmlFor="">To</label>
                            <div className="send-balance-input-group">
                                <div className="icon-img">
                                    <img src={walletIcon} alt="" />
                                </div>
                                <input type="text" placeholder="Mobile, email, or address" />
                            </div>
                        </div>
                        <div className="send-balance-form-group">
                            <label htmlFor="">Note</label>
                            <div className="send-balance-input-group">
                                <div className="icon-img">
                                    <img src={editIcon} alt="" />
                                </div>
                                <input type="text" placeholder="Optional message" />
                            </div>
                        </div>
                    </div>
                    <div className="primary-button-wallet mt-5" onClick={() => history.push(`${process.env.PUBLIC_URL}/crypto-send-currency`)}>Continue</div>
                    <div className="d-flex justify-content-between mt-2">
                        <span>BTC balance</span>
                        <span>0.00121094 BTC = $19.93</span>
                    </div>

                    <div
                        className={`provider-selection-box h-95 ${showCryptoCurrencySelect ? "hide-selection-box" : ""
                            }`}
                    >
                        <div className="provider-selection-header mb-3">
                            <div className="provider-heading text-center">
                                Select crypto currency
                            </div>
                            <div
                                className="close-down-arrow"
                                onClick={() =>
                                    setShowCryptoCurrencySelect(!showCryptoCurrencySelect)
                                }
                            >
                                <img src={arrowDownCaret} alt="" />
                            </div>
                        </div>
                        <Select
                            placeholder="Choose currency"
                            menuIsOpen={true}
                            onChange={handleCryptoChange}
                            filterOption={filterOption}
                            getOptionLabel={(option) => {
                                // let curr = Object.keys(option.currencies || {});
                                // return `${option.name} `;

                                return (
                                    <>
                                        <div className="custom-option-listing">
                                            <div className="img-box">
                                                <img src={option.image} alt="" />
                                            </div>
                                            <div className="listing_text_box">
                                                <div className="option-heading">{option.symbol}</div>
                                                <div className="option-subheading">{option.name}</div>
                                            </div>
                                        </div>
                                    </>
                                );
                            }}
                            getOptionValue={(option) => {
                                return option.name;
                            }}
                            options={options}
                            styles={customStyles}
                        />
                    </div>


                    <div className="card_footer">
                        <div className="footer_logo_img">
                            <img src={logo} alt="" />
                        </div>
                        <span>Secured by </span>
                        <span className="footer_logo">Ahrvo</span>
                    </div>
                </div>
            </div>
        </>
    );
};
