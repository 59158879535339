import React from "react";
import LogoLight2x from "../../images/logo2x.png";
import LogoDark2x from "../../images/logo-dark2x.png";
import ahrvoLogo from "../../assets/images/Ahrvo_Network_Logo.png"
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to={`${process.env.PUBLIC_URL}/crypto`} className="logo-link">
      <img className="logo-light logo-img" src={ahrvoLogo} alt="logo" />
      <img className="logo-dark logo-img" src={ahrvoLogo} alt="logo" />
      <h4 className="d-inline-block align-bottom ms-1">AHRVO</h4>
    </Link>
  );
};

export default Logo;
