export const orderActivityData = [
  {
    id: 1,
    icon1: "sign-btc bg-btc-dim icon-circle",
    icon2: "arrow-down-left bg-success-dim icon-circle",
    desc: "Buy Bitcoin",
    date: "02/10/2020",
    time: "11:37 PM",
    ref: "RE2309232",
    usd: "4,565.75",
    amount: "+ 0.2040",
  },
  {
    id: 2,
    icon1: "sign-eth bg-eth-dim icon-circle",
    icon2: "arrow-down-left bg-success-dim icon-circle",
    desc: "Buy Ethereum",
    date: "02/10/2020",
    time: "10:37 PM",
    ref: "RE2309232",
    usd: "2,039.39",
    amount: "+ 0.12600",
  },
  {
    id: 3,
    icon1: "sign-btc bg-btc-dim icon-circle",
    icon2: "arrow-up-right bg-purple-dim icon-circle",
    icon1Class: "bg-btc-dim",
    icon2Class: "bg-purple-dim",
    desc: "Sell Bitcoin",
    date: "02/10/2020",
    time: "10:45 PM",
    ref: "RE2309232",
    usd: "9,285.71 ",
    amount: "+ 0.94750",
  },
  {
    id: 4,
    icon1: "sign-eth bg-eth-dim icon-circle",
    icon2: "arrow-up-right bg-purple-dim icon-circle",
    desc: "Sell Etherum",
    date: "02/10/2020",
    time: "10:25 PM",
    ref: "RE2309232",
    usd: "12,596.75",
    amount: "+ 1.02050",
  },
  {
    id: 5,
    icon1: "sign-btc bg-btc-dim icon-circle",
    icon2: "arrow-down-left bg-success-dim icon-circle",
    desc: "Buy Bitcoin",
    date: "02/10/2020",
    time: "10:12 PM",
    ref: "RE2309232",
    usd: "400.00",
    amount: "+ 0.00056",
  },
  {
    id: 6,
    icon1: "sign-eth bg-eth-dim icon-circle",
    icon2: "arrow-up-right bg-purple-dim icon-circle",
    desc: "Sell Etherum",
    date: "02/10/2020",
    time: "05:15 PM",
    ref: "RE2309232",
    usd: "6,246.50",
    amount: "+ 0.02575",
  },
];
