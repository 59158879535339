import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import logo from "../../../../assets/images/Ahrvo_Network_Logo.png";
import backIcon from "../../../../assets/images/arrow_left.svg";


function FiatSendSame() {
    const itemList = [
        {
            avatar: 'AC',
            name: "Ayushua Chitransh",
            time: "INR account ending in 3990",
        },
        {
            avatar: 'IL',
            name: "IMAGINORLABS PRIVATE LIMITED",
            time: "INR account ending in 0414",
        },
        {
            avatar: 'RO',
            name: "Rahul Kumar Ojha",
            time: "INR account ending in @ybl",
        },
        {
            avatar: 'SV',
            name: "Shivam Verma",
            time: "INR account ending in 9152",
        },
    ];
    const [filteredList, setFilteredList] = useState(itemList);

    const filterBySearch = (event) => {
        const query = event.target.value.toLowerCase();
        const updatedList = itemList.filter((item) => {
            return (
                item.name.toLowerCase().includes(query) ||
                item.time.toLowerCase().includes(query)
            );
        });
        setFilteredList(updatedList);
    };
    return (
        <div className="page-layout">
            <div className="ahrvo-card">
                <Link to={`${process.env.PUBLIC_URL}/fiat-send-same-currency`}>
                    <div className="back_navigation">
                        <img src={backIcon} alt="" />
                    </div>
                </Link>
                <div className="card_header">
                    <div className="header_text">
                        {/* <h5>Who are you sending money to?</h5> */}
                    </div>
                </div>
                <h6 className="text-center mb-4">Who are you sending money to?</h6>
                <div className="search-header">
                    {/* <img src={searchIcon}  className='search-icon'/> */}
                    <em class="icon ni ni-search"></em>
                    {/* <div className="search-text">Search:</div> */}
                    <input id="search-box" onChange={filterBySearch} placeholder='Name, email, phone' />
                </div>
                <div className='parent-result'>
                    <div className='all'>All</div>
                    {filteredList.length === 0 ? (
                        <div className="no-results">No results found.</div>
                    ) : (
                        <ol>
                            {filteredList.map((item, index) => (
                                <li key={index}>
                                    <Link to={`${process.env.PUBLIC_URL}/fiat-send-same-reason`}>
                                        <div className="userAvatar">{item.avatar}</div>
                                        <div className="userName-parent">
                                            <div className="userName">{item.name}</div>
                                            <div className='time'>{item.time}</div>
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </ol>
                    )}
                </div>
                <div className="card_footer">
                    <div className="footer_logo_img">
                        <img src={logo} alt="" />
                    </div>
                    <span>Secured by </span>
                    <span className="footer_logo">Ahrvo</span>
                </div>
            </div>
        </div>
    )
}

export default FiatSendSame;
