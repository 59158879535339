export const investData = [
  {
    id: 1,
    plan: "P1",
    title: "Silver",
    desc: "Daily 4.76% for 21 Days",
    who: "Janice Caroll",
    theme: "pink-dim",
    amount: "1.094780",
    date: "18/10/2019",
    status: "84",
  },
  {
    id: 2,
    plan: "P2",
    title: "Diamond",
    desc: "Daily 8.52% for 14 Days",
    who: "Victoria Aguilar",
    amount: "1.094780",
    date: "18/10/2019",
    theme: "primary-dim",
    status: "Completed",
  },
  {
    id: 3,
    plan: "P3",
    title: "Platinum",
    desc: "Daily 14.82% for 7 Days",
    who: "Emma Henry",
    amount: "1.094780",
    date: "18/10/2019",
    theme: "purple-dim",
    status: "Completed",
  },
  {
    id: 4,
    plan: "P1",
    title: "Silver",
    desc: "Daily 4.76% for 21 Days",
    who: "Alice Ford",
    amount: "1.094780",
    date: "18/10/2019",
    theme: "success-dim",
    status: "Completed",
  },
];
