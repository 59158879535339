import React, { useState } from "react";
import { Link } from "react-router-dom";
import backIcon from "../../../../assets/images/arrow_left.svg";
import logo from "../../../../assets/images/Ahrvo_Network_Logo.png";
import creditCard from "../../../../assets/images/creditcard-colored.svg";
import impsIcon from "../../../../assets/images/imps-colored.svg";
import ellipsis from "../../../../assets/images/ellipsis.svg";
import walletIcon from "../../../../assets/images/wallet-svgrepo-com.svg";
import editIcon from "../../../../assets/images/edit-svgrepo-com.svg";
import polygonIcon from "../../../../assets/images/currency-logo/polygon.svg";
import swapIcon from "../../../../assets/images/swap-thin-svgrepo-com.svg";
import { useHistory } from "react-router-dom";
import arrowDownCaret from "../../../../assets/images/arrow_down_caret.svg";
import { useEffect } from "react";
import Select from "react-select";

export const CryptoSwap = () => {
  const history = useHistory();

  const [options, setOptions] = React.useState([]);
  const [showFiatCurrencySelect, setShowFiatCurrencySelect] = useState(true);
  const [showCryptoCurrencySelect, setShowCryptoCurrencySelect] =
    useState(true);
  const [selectedFiatCurrency, setSelectedFiatCurrency] = useState("Select");
  const [selectedCryptoCurrency, setSelectedCryptoCurrency] =
    useState("Select");

  function handleFiatChange(e) {
    // props.setCountryName(e.code);
    console.log(e);

    console.log("!!!!!");
    let curr = Object.keys(e.currencies || {});
    // console.log(curr[0], "curr")
    setShowFiatCurrencySelect(!showFiatCurrencySelect);
    console.log(`${e.flag} ${e.name.common} ${curr[0]}`);
    setSelectedFiatCurrency(`${e.flag} ${curr[0]}`);
  }

  console.log(selectedFiatCurrency);

  function handleCryptoChange(e) {
    // props.setCountryName(e.code);
    console.log(e);

    console.log("!!!!!");
    let curr = Object.keys(e.currencies || {});
    // console.log(curr[0], "curr")
    setShowCryptoCurrencySelect(!showCryptoCurrencySelect);
    console.log(`${e.flag} ${e.name.common} ${curr[0]}`);
    setSelectedCryptoCurrency(`${e.flag} ${curr[0]}`);
  }

  useEffect(() => {
    async function FetchData() {
      fetch(`https://restcountries.com/v3.1/all`, { mode: "cors" })
        .then((res) => res.json())
        .then((result) => {
          setOptions(result);
          // console.log(Object.keys(result[0].currencies)[0])
          // console.log(result)
        });
    }
    FetchData();
  }, [0]);

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black",
      padding: 5,
    }),
  };

  return (
    <>
      <div className="page-layout">
        <div className="ahrvo-card">
          <div
            className={`backdrop-layer ${showFiatCurrencySelect ? "hide" : ""}`}
            onClick={() => setShowFiatCurrencySelect(!showFiatCurrencySelect)}
          ></div>
          <Link to={`${process.env.PUBLIC_URL}/crypto-home`}>
            <div className="back_navigation">
              <img src={backIcon} alt="" />
            </div>
          </Link>
          <div className="card_header">
            <div className="header_text">
              <h5>Swap</h5>
            </div>
          </div>
          <div className="primary-swap-screen mt-4">
            <div className="swap-form-group">
              <div className="d-flex justify-content-between mb-1">
                <label htmlFor="">Swap from</label>
                <div className="label-action">Max</div>
              </div>
              <div className="swap-input-group input-with-select">
                <div className="currency_select_box">
                  <div
                    className="demo-box"
                    onClick={() =>
                      setShowFiatCurrencySelect(!showFiatCurrencySelect)
                    }
                  ></div>
                  {/* <button  onClick={() => setShowFiatCurrencySelect(!showFiatCurrencySelect)}>Click me</button> */}
                  <select name="" id="">
                    <option value="INR">{selectedFiatCurrency}</option>
                  </select>
                </div>

                <input type="text" />
              </div>
              <div className="swap-balance-info">
                150 USDT available to swap
              </div>
            </div>
            <div className="swap-action my-2 text-end cursor-pointer">
              <img src={swapIcon} alt="" />
            </div>
            <div className="swap-form-group ">
              <div className="label-group">
                <label htmlFor="" className="mb-1">
                  Swap to
                </label>
              </div>
              <div className="swap-input-group">
                <div className="currency_select_box">
                  <div
                    className="demo-box"
                    onClick={() =>
                      setShowCryptoCurrencySelect(!showCryptoCurrencySelect)
                    }
                  ></div>
                  {/* <button  onClick={() => setShowFiatCurrencySelect(!showFiatCurrencySelect)}>Click me</button> */}
                  <select name="" id="">
                    <option value="INR">{selectedCryptoCurrency}</option>
                  </select>
                </div>
              </div>
              <div className="swap-info mt-1">
                <b>Verified on 8 sources.</b>Always confirm the token address on{" "}
                <a href="#">Etherscan</a>.
              </div>
            </div>
            <div className="transparent-btn">Advanced options</div>
          </div>
          <div
            className="primary-button-wallet mt-3"
            onClick={() =>
              history.push(`${process.env.PUBLIC_URL}/crypto-swap-review`)
            }
          >
            Review swap
          </div>
          <div className="terms-link text-center my-1">
            <a href="#" className="d-inline-block py-2">
              Terms of service
            </a>
          </div>
          <div
            className={`provider-selection-box h-95 ${
              showFiatCurrencySelect ? "hide-selection-box" : ""
            }`}
          >
            <div className="provider-selection-header mb-3">
              <div className="provider-heading text-center">
                Select fiat currency
              </div>
              <div
                className="close-down-arrow"
                onClick={() =>
                  setShowFiatCurrencySelect(!showFiatCurrencySelect)
                }
              >
                <img src={arrowDownCaret} alt="" />
              </div>
            </div>
            <Select
              placeholder="Choose currency"
              menuIsOpen={true}
              onChange={handleFiatChange}
              getOptionLabel={(option) => {
                let curr = Object.keys(option.currencies || {});
                return `${option.flag} ${option.name.common} ${curr[0]}`;
              }}
              getOptionValue={(option) => {
                return option.area;
              }}
              options={options}
              styles={customStyles}
            />
          </div>
          <div
            className={`provider-selection-box h-95 ${
              showCryptoCurrencySelect ? "hide-selection-box" : ""
            }`}
          >
            <div className="provider-selection-header mb-3">
              <div className="provider-heading text-center">
                Select Crypto currency
              </div>
              <div
                className="close-down-arrow"
                onClick={() =>
                  setShowCryptoCurrencySelect(!showCryptoCurrencySelect)
                }
              >
                <img src={arrowDownCaret} alt="" />
              </div>
            </div>
            <Select
              placeholder="Choose currency"
              menuIsOpen={true}
              onChange={handleCryptoChange}
              getOptionLabel={(option) => {
                let curr = Object.keys(option.currencies || {});
                return `${option.flag} ${option.name.common} ${curr[0]}`;
              }}
              getOptionValue={(option) => {
                return option.area;
              }}
              options={options}
              styles={customStyles}
            />
          </div>

          <div className="card_footer">
            <div className="footer_logo_img">
              <img src={logo} alt="" />
            </div>
            <span>Secured by </span>
            <span className="footer_logo">Ahrvo</span>
          </div>
        </div>
      </div>
    </>
  );
};
