import React from "react";
import { Link } from "react-router-dom";
import backIcon from "../../assets/images/arrow_left.svg";
import logo from "../../assets/images/Ahrvo_Network_Logo.png";
import creditCard from "../../assets/images/creditcard-colored.svg";
import impsIcon from "../../assets/images/imps-colored.svg";
import ellipsis from "../../assets/images/ellipsis.svg";

export const BuyMatic = () => {
  return (
    <>
      <div className="page-layout">
        <div className="ahrvo-card">
          <Link to={`${process.env.PUBLIC_URL}/crypto-home`}>
            <div className="back_navigation">
              <img src={backIcon} alt="" />
            </div>
          </Link>
          <div className="card_header">
            <div className="header_text">
              <div>Polygon</div>
            </div>
          </div>
          <h6 className="mb-4 text-center">Buy MATIC</h6>
          <div className="currency-selection-box">
            <div className="currency-selection-box-heading">Buy</div>
            <div className="selection_box_area">
              <input className="currency_input" defaultValue={"$50"} />
              <div className="country_select_box">
                <select name="" id="">
                  <option value="INR">USD</option>
                  <option value="ARS">ARS</option>
                  <option value="AUD">AUD</option>
                </select>
              </div>
            </div>
          </div>

          <div className="currency-selection-box mt-2">
            <div className="currency-selection-box-heading">Recieve</div>
            <div className="selection_box_area">
              <input className="currency_input" defaultValue={52.125871} />
              <div className="country_select_box">
                <select name="" id="">
                  <option value="INR">MATIC</option>
                  <option value="ARS">ARS</option>
                  <option value="AUD">AUD</option>
                </select>
              </div>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <span>Exchange rate</span>
            <span>1 MATIC = $0.89</span>
          </div>
          <div className="d-flex justify-content-between mt-1">
            <span>52.125871 MATIC</span>
            <span>$ 46.50</span>
          </div>
          <div className="d-flex justify-content-between mt-1">
            <span>ACH transfer Fees</span>
            <span>$ 3.50</span>
          </div>
          <div className="d-flex justify-content-between mt-1">
            <span><b>Total</b></span>
            <span><b>$ 50.00</b></span>
          </div>
          <div className="text-center mt-4">By continuing, you agree to our Privacy Policy</div>
          <div className="primary-button-wallet mt-1">Continue</div>
          <div className="card_footer">
            <div className="footer_logo_img">
              <img src={logo} alt="" />
            </div>
            <span>Secured by </span>
            <span className="footer_logo">Ahrvo</span>
          </div>
        </div>
      </div>
    </>
  );
};
