import React, { useState } from "react";
import logo from "../../../../assets/images/Ahrvo_Network_Logo.png";
import arrowDown from "../../../../assets/images/arrow_down.svg";
import sendIcon from "../../../../assets/images/sendIcon.svg";
import recieveIcon from "../../../../assets/images/recieveIcon.svg";
import polygonIcon from "../../../../assets/images/currency-logo/polygon.svg";
import bankIcon from "../../../../assets/images/currency-logo/bank.svg";
import sideArrowIcon from "../../../../assets/images/arrow_right.svg";
import bankCardIcon from "../../../../assets/images/currency-logo/bankCard.svg";
import applePay from "../../../../assets/images/currency-logo/applePay.svg";
import googlePayIcon from "../../../../assets/images/currency-logo/googlePay.svg";
import linkIcon from "../../../../assets/images/linkIcon.svg";
import backIcon from "../../../../assets/images/arrow_left.svg";
import arrowDownCaret from "../../../../assets/images/arrow_down_caret.svg";
import sardineIcon from "../../../../assets/images/currency-logo/sardine.svg";
import onRamperIcon from "../../../../assets/images/currency-logo/onRamper.svg";
import coinify from "../../../../assets/images/currency-logo/coinify.svg";
import transakLogo from "../../../../assets/images/currency-logo/transak-logo.svg";
import SGVeteriesLogo from "../../../../assets/images/currency-logo/sgveteries.svg";
import { Link } from "react-router-dom";

export const CryptoBuyMethod = () => {
  const [showProiderBox, setShowProviderBox] = useState(false);

  return (
    <>
      <div className="page-layout">
        <div className="ahrvo-card">
          <div
            className={`backdrop-layer ${showProiderBox ? "hide" : ""}`}
            onClick={() => setShowProviderBox(!showProiderBox)}
          ></div>
          <Link to={`${process.env.PUBLIC_URL}/crypto-home`}>
            <div className="back_navigation">
              <img src={backIcon} alt="" />
            </div>
          </Link>
          <div className="card_header">
            <div className="header_text">
              <div>Polygon</div>
            </div>
            {/* <div className="header_logo">
                            <img src={logo} alt="logo" />
                        </div> */}
          </div>

          {/* <div className="payment-method-box">
            <div className="method-heading">Payment Method</div>
            <p className="method- mb-4">
              New York Resident?{" "}
              <span>
                Head here <img src={linkIcon} alt="" />
              </span>
            </p>
            <div
              className="bank-payment-button"
              onClick={() => setShowProviderBox(!showProiderBox)}
            >
              <div className="button-box">
                <div className="bank-icon">
                  <img src={bankIcon} alt="" />
                </div>
                <div className="bank-method">Instant Bank Transfer</div>
              </div>
              <div className="side-arrow">
                <img src={sideArrowIcon} alt="" />
              </div>
            </div>
            <div className="bank-payment-button">
              <div className="button-box">
                <div className="bank-icon">
                  <img src={bankCardIcon} alt="" />
                </div>
                <div className="bank-method">Credit or Debit</div>
              </div>
              <div className="side-arrow">
                <img src={sideArrowIcon} alt="" />
              </div>
            </div>
            <div className="bank-payment-button">
              <div className="button-box">
                <div className="bank-icon">
                  <img src={applePay} alt="" />
                </div>
                <div className="bank-method">Apple Pay</div>
              </div>
              <div className="side-arrow">
                <img src={sideArrowIcon} alt="" />
              </div>
            </div>
            <div className="bank-payment-button">
              <div className="button-box">
                <div className="bank-icon">
                  <img src={googlePayIcon} alt="" />
                </div>
                <div className="bank-method">Google Pay</div>
              </div>
              <div className="side-arrow">
                <img src={sideArrowIcon} alt="" />
              </div>
            </div>
          </div> */}
          <div
            className={`provider-selection-box ${showProiderBox ? "hide-selection-box" : ""
              }`}
          >
            <div className="provider-selection-header">
              <div className="provider-heading">Choose a provider</div>
              <div
                className="close-down-arrow"
                onClick={() => setShowProviderBox(!showProiderBox)}
              >
                <img src={arrowDownCaret} alt="" />
              </div>
            </div>
            <div className="provider-options">
              <Link
                className="provider-option"
                to={`${process.env.PUBLIC_URL}/crypto-buy`}
              >
                <div className="provider-option-left">
                  <div className="provider-icon">
                    <img src={coinify} alt="" />
                  </div>
                </div>
                <div className="provider-text">$3,000 daily limit</div>
              </Link>
              <Link
                className="provider-option"
                to={`${process.env.PUBLIC_URL}/buyMatic`}
              >
                <div className="provider-option-left">
                  <div className="provider-icon">
                    <img src={transakLogo} alt="" />
                  </div>
                </div>
                <div className="provider-text">Limits vary</div>
              </Link>
              <div className="provider-option">
                <div className="provider-option-left">
                  <div className="provider-icon">
                    <img src={SGVeteriesLogo} alt="" />
                  </div>
                </div>
                <div className="provider-text">$3,000 daily limit</div>
              </div>
            </div>
          </div>
          <div className="card_footer">
            <div className="footer_logo_img">
              <img src={logo} alt="" />
            </div>
            <span>Secured by </span>
            <span className="footer_logo">Ahrvo</span>
          </div>
        </div>
      </div>
    </>
  );
};
