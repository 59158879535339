import React, { useState } from 'react'
import arrowDownCaret from "../../../assets/images/arrow_down_caret.svg";
import { Link } from 'react-router-dom/cjs/react-router-dom';
import backIcon from "../../../assets/images/arrow_left.svg";

function TransferMethodSelectWithdraw() {
  const [showProiderBox, setShowProviderBox] = useState(false);

  return (
    <div className="page-layout">
      <div className="ahrvo-card">
        <div
          className={`provider-selection-box h-100 ${showProiderBox ? "hide-selection-box" : ""
            }`}
        >
        <Link to={`${process.env.PUBLIC_URL}/crypto/wallets`}>
            <div className="back_navigation">
              <img src={backIcon} alt="" />
            </div>
          </Link>
          <div className="provider-selection-header mt-5">
            <div className="provider-heading">Choose a wallet</div>
            {/* <div
              className="close-down-arrow"
              onClick={() => setShowProviderBox(!showProiderBox)}
            >
              <img src={arrowDownCaret} alt="" />
            </div> */}
          </div>
          <div className="provider-options">
            <Link
              className="provider-option"
              to={`${process.env.PUBLIC_URL}/crypto-withdraw-method-payment`}
            >
              <div className="provider-text"><strong>Crypto Wallet</strong></div>
            </Link>
            <Link
              className="provider-option"
              to={`${process.env.PUBLIC_URL}/fiat-withdraw-method-payment`}
            >
              <div className="provider-text"><strong>Fiat Wallet</strong></div>
            </Link>
            {/* <div className="provider-option">
              <div className="provider-option-left">
                <div className="provider-icon">
                  <img src={SGVeteriesLogo} alt="" />
                </div>
              </div>
              <div className="provider-text">$3,000 daily limit</div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransferMethodSelectWithdraw

