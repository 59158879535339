import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { RedirectAs404 } from "./utils/Utils";
import PrivateRoute from "./route/PrivateRoute";

import Layout from "./layout/Index";

import Error404Classic from "./pages/error/404-classic";
import Error404Modern from "./pages/error/404-modern";
import Error504Modern from "./pages/error/504-modern";
import Error504Classic from "./pages/error/504-classic";

import Faq from "./pages/others/Faq";
import Terms from "./pages/others/Terms";

import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Success from "./pages/auth/Success";
import InvoicePrint from "./pages/pre-built/invoice/InvoicePrint";
import { OTPVerification } from "./pages/AuthPages/OTPVerification";
import { FiatHome, MoneyHome } from "./pages/MoneyTransactions/FiatScreens/FiatHome";
import { BuyHome } from "./pages/MoneyTransactions/BuyHome";
import { RecieveScreen } from "./pages/MoneyTransactions/RecieveScreen";
import { BuyCrypto } from "./pages/MoneyTransactions/BuyCrypto";
import { BuyMatic } from "./pages/MoneyTransactions/BuyMatic";
import { SendFormPage } from "./pages/MoneyTransactions/SendFormPage";
import { SendReviewPage } from "./pages/MoneyTransactions/SendReviewPage";
import { SendMoneyOTP } from "./pages/MoneyTransactions/SendMoneyOTP";
import { SwapHome } from "./pages/MoneyTransactions/SwapHome";
import { CryptoHome } from "./pages/MoneyTransactions/cryptoScreens/CryptoHome";
import { CryptoPaymentMethod } from "./pages/MoneyTransactions/cryptoScreens/BuyScreens/CryptoPaymentMethod";
import { CryptoBuy } from "./pages/MoneyTransactions/cryptoScreens/BuyScreens/CryptoBuy";
import { CryptoRecieve } from "./pages/MoneyTransactions/cryptoScreens/RecieveScreens/CryptoRecieve";
import { CryptoSend } from "./pages/MoneyTransactions/cryptoScreens/SendScreens/CryptoSend";
import { CryptoSendReview } from "./pages/MoneyTransactions/cryptoScreens/SendScreens/CryptoSendReview";
import { CryptoSendVerify } from "./pages/MoneyTransactions/cryptoScreens/SendScreens/CryptoSendVerify";
import { FiatPaymentMethod } from "./pages/MoneyTransactions/FiatScreens/BuyScreens/FiatPaymentMethod";
import { FiatBuy } from "./pages/MoneyTransactions/FiatScreens/BuyScreens/FiatBuy";
import { FiatRecieve } from "./pages/MoneyTransactions/FiatScreens/RecieveScreens/FiatRecieve";
import { FiatSend } from "./pages/MoneyTransactions/FiatScreens/SendScreens/FiatSend";
import { CryptoSwap } from "./pages/MoneyTransactions/cryptoScreens/SwapScreens/CryptoSwap";
import { CryptoSwapReview } from "./pages/MoneyTransactions/cryptoScreens/SwapScreens/CryptoSwapReview";
import { FiatSwap } from "./pages/MoneyTransactions/FiatScreens/SwapScreens/FiatSwap";
import { FiatSwapForm } from "./pages/MoneyTransactions/FiatScreens/SwapScreens/FiatSwapForm";
import { CryptoBuyMethod } from "./pages/MoneyTransactions/cryptoScreens/BuyScreens/CryptoBuyMethod";
import { CryptoBuyVerification } from "./pages/MoneyTransactions/cryptoScreens/BuyScreens/CryptoBuyVerification";
import { CryptoSwapVerify } from "./pages/MoneyTransactions/cryptoScreens/SwapScreens/CryptoSwapVerify";
import { FiatBuyVerification } from "./pages/MoneyTransactions/FiatScreens/BuyScreens/FiatBuyVerification";
import { FiatSwapVerify } from "./pages/MoneyTransactions/FiatScreens/SwapScreens/FiatSwapVerify";
import { FiatSendVerification } from "./pages/MoneyTransactions/FiatScreens/SendScreens/FiatSendVerification";
import FiatSendSame from "./pages/MoneyTransactions/FiatScreens/SendScreens/FiatSendSame";
import FiatSendSameReason from "./pages/MoneyTransactions/FiatScreens/SendScreens/FiatSendSameReason";
import FiatSendSameReview from "./pages/MoneyTransactions/FiatScreens/SendScreens/FiatSendSameReview";
import { FiatSendSameVerification } from "./pages/MoneyTransactions/FiatScreens/SendScreens/FiatSendSameVerification";
import WalletFiat from "./pages/MoneyTransactions/wallets/WalletFiat";
import CryptoSendSame from "./pages/MoneyTransactions/cryptoScreens/SendScreens/CryptoSendSame";
import CryptoSendSameReason from "./pages/MoneyTransactions/cryptoScreens/SendScreens/CryptoSendSameReason";
import CryptoSendSameReview from "./pages/MoneyTransactions/cryptoScreens/SendScreens/CryptoSendSameReview";
import { CryptoSendSameVerification } from "./pages/MoneyTransactions/cryptoScreens/SendScreens/CryptoSendSameVerification";
import { CryptoSendSameCurrency } from "./pages/MoneyTransactions/cryptoScreens/SendScreens/CryptoSendSameCurrency";
import { FiatSendSameCurrency } from "./pages/MoneyTransactions/FiatScreens/SendScreens/FiatSendSameCurrency";
import { DepositMethod } from "./pages/panel/crypto-panel/DepositMethod";
import { WithdrawMethod } from "./pages/panel/crypto-panel/WithdrawMethod";
import FiatSendCurrency from "./pages/MoneyTransactions/FiatScreens/SendScreens/FiatSendCurrency";
import FiatSendReason from "./pages/MoneyTransactions/FiatScreens/SendScreens/FiatSendReason";
import FiatSendReview from "./pages/MoneyTransactions/FiatScreens/SendScreens/FiatSendReview";
import CryptoSendCurrency from "./pages/MoneyTransactions/cryptoScreens/SendScreens/CryptoSendCurrency";
import CryptoSendReason from "./pages/MoneyTransactions/cryptoScreens/SendScreens/CryptoSendReason";
import { CryptoBuyReview } from "./pages/MoneyTransactions/cryptoScreens/BuyScreens/CryptoBuyReview";
import FiatBuyReview from "./pages/MoneyTransactions/FiatScreens/BuyScreens/FiatBuyReview";
import FiatSwapReview from "./pages/MoneyTransactions/FiatScreens/SwapScreens/FiatSwapReview";
import DepositMethodSelect from "./pages/panel/crypto-panel/DepositMethodSelect";
import { DepositCrypto } from "./pages/panel/crypto-panel/DepositCrypto";
import TransferMethodSelect from "./pages/MoneyTransactions/CommonScreens/TransferMethodSelect";
import { CryptoDepositMethodPayment } from "./pages/MoneyTransactions/cryptoScreens/DepositMethods/CryptoDepositMethodPayment";
import { CryptoProviders } from "./pages/MoneyTransactions/cryptoScreens/DepositMethods/CryptoProviders";
import { FiatDepositMethodPayment } from "./pages/MoneyTransactions/FiatScreens/DepositMethods/FiatDepositMethodPayment";
import { FiatProviders } from "./pages/MoneyTransactions/FiatScreens/DepositMethods/FiatProviders";
import TransferMethodSelectWithdraw from "./pages/MoneyTransactions/CommonScreens/TransferMethodSelectWithdraw";
import { CryptoWithdrawMethodPayment } from "./pages/MoneyTransactions/cryptoScreens/WithdrawMethods/CryptoWithdrawMethodPayment";
import { CryptoWithdrawProviders } from "./pages/MoneyTransactions/cryptoScreens/WithdrawMethods/CryptoWithdrawProviders";
import { FiatWithdrawMethodPayment } from "./pages/MoneyTransactions/FiatScreens/WithdrawMethods/FiatWithdrawMethodPayment";
import { FiatWithdrawProviders } from "./pages/MoneyTransactions/FiatScreens/WithdrawMethods/FiatWithdrawProviders";

const App = () => {
  return (
    <Switch>
      {/* Auth Pages */}
      <Route exact path={`${process.env.PUBLIC_URL}/auth-success`} component={Success}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/auth-reset`} component={ForgotPassword}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/auth-register`} component={Register}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/`} component={Login}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/otpVerification`} component={OTPVerification}></Route>

      {/* wallet pages */}

      <Route path={`${process.env.PUBLIC_URL}/buyHome`} component={BuyHome}></Route>
      <Route path={`${process.env.PUBLIC_URL}/recieveMoney`} component={RecieveScreen}></Route>
      <Route path={`${process.env.PUBLIC_URL}/buyCrypto`} component={BuyCrypto}></Route>
      <Route path={`${process.env.PUBLIC_URL}/buyMatic`} component={BuyMatic}></Route>
      <Route path={`${process.env.PUBLIC_URL}/sendMoneyForm`} component={SendFormPage}></Route>
      <Route path={`${process.env.PUBLIC_URL}/sendReview`} component={SendReviewPage}></Route>
      <Route path={`${process.env.PUBLIC_URL}/send-money-otp`} component={SendMoneyOTP}></Route>
      <Route path={`${process.env.PUBLIC_URL}/swap-money`} component={SwapHome}></Route>

      {/* Deposit method pages */}

      
      <Route path={`${process.env.PUBLIC_URL}/transfer-method-select`} component={TransferMethodSelect}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-deposit-method-payment`} component={CryptoDepositMethodPayment}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-deposit-method-providers`} component={CryptoProviders}></Route>
      
      <Route path={`${process.env.PUBLIC_URL}/fiat-deposit-method-payment`} component={FiatDepositMethodPayment}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-deposit-method-providers`} component={FiatProviders}></Route>

      {/* withdraw method screens */}

      <Route path={`${process.env.PUBLIC_URL}/transfer-method-select-withdraw`} component={TransferMethodSelectWithdraw}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-withdraw-method-payment`} component={CryptoWithdrawMethodPayment}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-withdraw-method-providers`} component={CryptoWithdrawProviders}></Route>

      <Route path={`${process.env.PUBLIC_URL}/fiat-withdraw-method-payment`} component={FiatWithdrawMethodPayment}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-withdraw-method-providers`} component={FiatWithdrawProviders}></Route>



      {/* crypto screens */}
      <Route path={`${process.env.PUBLIC_URL}/crypto-home`} component={CryptoHome}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-payment-method`} component={CryptoPaymentMethod}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-deposit-method`} component={DepositMethod}></Route>
      <Route path={`${process.env.PUBLIC_URL}/deposit-method-select`} component={DepositMethodSelect}></Route>
      <Route path={`${process.env.PUBLIC_URL}/deposit-method-crypto`} component={DepositCrypto}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-withdraw-method`} component={WithdrawMethod}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-buy`} component={CryptoBuy}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-buy-review`} component={CryptoBuyReview}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-recieve`} component={CryptoRecieve}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-send`} component={CryptoSend}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-send-currency`} component={CryptoSendCurrency}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-send-reason`} component={CryptoSendReason}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-send-review`} component={CryptoSendReview}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-send-verify`} component={CryptoSendVerify}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-swap`} component={CryptoSwap}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-swap-review`} component={CryptoSwapReview}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-buy-method`} component={CryptoBuyMethod}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-buy-verify`} component={CryptoBuyVerification}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-swap-verify`} component={CryptoSwapVerify}></Route>      
      <Route path={`${process.env.PUBLIC_URL}/crypto-send-same-currency`} component={CryptoSendSameCurrency}></Route>
      <Route path={`${process.env.PUBLIC_URL}/crypto-send-same`} component={CryptoSendSame}></Route>      
      <Route path={`${process.env.PUBLIC_URL}/crypto-send-same-reason`} component={CryptoSendSameReason}></Route>      
      <Route path={`${process.env.PUBLIC_URL}/crypto-send-same-review`} component={CryptoSendSameReview}></Route>      
      <Route path={`${process.env.PUBLIC_URL}/crypto-send-same-verify`} component={CryptoSendSameVerification}></Route>      



      {/* Fiat Screens */}
      <Route path={`${process.env.PUBLIC_URL}/fiat-home`} component={FiatHome}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-payment-method`} component={FiatPaymentMethod}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-buy`} component={FiatBuy}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-buy-review`} component={FiatBuyReview}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-recieve`} component={FiatRecieve}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-send`} component={FiatSend}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-send-currency`} component={FiatSendCurrency}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-send-reason`} component={FiatSendReason}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-send-review`} component={FiatSendReview}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-swap`} component={FiatSwap}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-swap-review`} component={FiatSwapReview}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-swap-form`} component={FiatSwapForm}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-buy-verify`} component={FiatBuyVerification}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-swap-verify`} component={FiatSwapVerify}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-send-verify`} component={FiatSendVerification}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-send-same-currency`} component={FiatSendSameCurrency}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-send-same`} component={FiatSendSame}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-send-same-reason`} component={FiatSendSameReason}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-send-same-review`} component={FiatSendSameReview}></Route>
      <Route path={`${process.env.PUBLIC_URL}/fiat-send-same-verify`} component={FiatSendSameVerification}></Route>



      {/* Print Pages */}
      <Route exact path={`${process.env.PUBLIC_URL}/invoice-print/:id`} component={InvoicePrint}></Route>

      {/* Helper pages */}
      <Route exact path={`${process.env.PUBLIC_URL}/auths/terms`} component={Terms}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/auths/faq`} component={Faq}></Route>

      <Route exact path={`${process.env.PUBLIC_URL}/invoice-print`} component={InvoicePrint}></Route>

      {/*Error Pages*/}
      <Route exact path={`${process.env.PUBLIC_URL}/errors/404-classic`} component={Error404Classic}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/errors/504-modern`} component={Error504Modern}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/errors/404-modern`} component={Error404Modern}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/errors/504-classic`} component={Error504Classic}></Route>

      {/*Main Routes*/}
      <PrivateRoute exact path="" component={Layout}></PrivateRoute>
      <Route component={RedirectAs404}></Route>
    </Switch>
  );
};
export default withRouter(App);
