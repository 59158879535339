import React from "react";
import { Link } from "react-router-dom";
import backIcon from "../../../../assets/images/arrow_left.svg";
import logo from "../../../../assets/images/Ahrvo_Network_Logo.png";
import creditCard from "../../../../assets/images/creditcard-colored.svg";
import impsIcon from "../../../../assets/images/imps-colored.svg";
import ellipsis from "../../../../assets/images/ellipsis.svg";
import arrowDownCaret from "../../../../assets/images/arrow_down_caret.svg";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useHistory } from "react-router-dom";


export const FiatSendSameCurrency = () => {
  const [options, setOptions] = React.useState([]);
  const [showFiatCurrencySelect, setShowFiatCurrencySelect] = useState(true);
  const [showCryptoCurrencySelect, setShowCryptoCurrencySelect] =
    useState(true);
  const [selectedFiatCurrency, setSelectedFiatCurrency] = useState("Select");
  const [selectedCryptoCurrency, setSelectedCryptoCurrency] =
    useState("Select");
  const history = useHistory();


  function handleFiatChange(e) {
    let curr = Object.keys(e.currencies || {});
    setShowFiatCurrencySelect(!showFiatCurrencySelect);
    setSelectedFiatCurrency(`${e.flag} ${curr[0]}`);
  }

  useEffect(() => {
    async function FetchData() {
      fetch(`https://restcountries.com/v3.1/all`, { mode: "cors" })
        .then((res) => res.json())
        .then((result) => {
          setOptions(result);
          // console.log(Object.keys(result[0].currencies)[0])
          // console.log(result)
        });
    }
    FetchData();
  }, [0]);

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black",
      padding: 5,
    }),
  };

  const handleSendFiat = () => {
    history.push(`${process.env.PUBLIC_URL}/fiat-send-same`);
  }

  return (
    <>
      <div className="page-layout">
        <div className="ahrvo-card">
          <div
            className={`backdrop-layer ${showFiatCurrencySelect ? "hide" : ""}`}
            onClick={() => setShowFiatCurrencySelect(!showFiatCurrencySelect)}
          ></div>
          <Link to={`${process.env.PUBLIC_URL}/fiat-send`}>
            <div className="back_navigation">
              <img src={backIcon} alt="" />
            </div>
          </Link>
          <div className="card_header">
            <div className="header_text">
              {/* <div>Polygon</div> */}
            </div>
          </div>
          <h6 className="text-center mb-4">Send Fiat</h6>
          <div className="currency-selection-box">
            <div className="currency-selection-box-heading">Recipient gets exactly</div>
            <div className="selection_box_area">
              <input className="currency_input" defaultValue={30} />
              <div className="country_select_box">
                <div
                  className="demo-box"
                  onClick={() =>
                    setShowFiatCurrencySelect(!showFiatCurrencySelect)
                  }
                ></div>
                {/* <button  onClick={() => setShowFiatCurrencySelect(!showFiatCurrencySelect)}>Click me</button> */}
                <select name="" id="">
                  <option value="INR">{selectedFiatCurrency}</option>
                </select>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <div><em class="icon ni ni-plus-sm"></em> 0.44 USD</div>
            <div>Low cost transfer fee</div>
          </div>
          <div className="d-flex justify-content-end mt-5">
            <div><strong>Total</strong></div>
          </div>
          <div className="d-flex justify-content-between">
            <div>You send</div>
            <div><strong>100.44 USD</strong></div>
          </div>
          <p className="mt-4 mb-1">Pay via</p>
          <div className="other-payments-area">
            <div className="other-payment-box">
              <div className="payment-method-img">
                <img src={creditCard} alt="" />
              </div>
              <div className="payment-method-text">Credit Card</div>
            </div>
            <div className="other-payment-box">
              <div className="payment-method-img">
                <img src={creditCard} alt="" />
              </div>
              <div className="payment-method-text">Transfer</div>
            </div>
            <div className="other-payment-box">
              <div className="payment-method-img">
                <img src={ellipsis} alt="" />
              </div>
              <div className="payment-method-text">More</div>
            </div>
          </div>
          <div className="primary-button-wallet mt-5" onClick={()=> handleSendFiat()}>Send</div>
          <div
            className={`provider-selection-box h-95 ${showFiatCurrencySelect ? "hide-selection-box" : ""
              }`}
          >
            <div className="provider-selection-header mb-3">
              <div className="provider-heading text-center">
                Select fiat currency
              </div>
              <div
                className="close-down-arrow"
                onClick={() =>
                  setShowFiatCurrencySelect(!showFiatCurrencySelect)
                }
              >
                <img src={arrowDownCaret} alt="" />
              </div>
            </div>
            <Select
              placeholder="Choose currency"
              menuIsOpen={true}
              onChange={handleFiatChange}
              getOptionLabel={(option) => {
                let curr = Object.keys(option.currencies || {});
                return `${option.flag} ${option.name.common} ${curr[0]}`;
              }}
              getOptionValue={(option) => {
                return option.area;
              }}
              options={options}
              styles={customStyles}
            />
          </div>
          <div className="card_footer">
            <div className="footer_logo_img">
              <img src={logo} alt="" />
            </div>
            <span>Secured by </span>
            <span className="footer_logo">Ahrvo</span>
          </div>
        </div>
      </div>
    </>
  );
};
