import React from "react";
import { Link } from "react-router-dom";
import backIcon from "../../../../assets/images/arrow_left.svg";
import logo from "../../../../assets/images/Ahrvo_Network_Logo.png";
import arrowDown from "../../../../assets/images/arrow_down.svg";
import USDTIcon from "../../../../assets/images/currency-logo/usdt.svg";
import USDCIcon from "../../../../assets/images/currency-logo/usd-coin-usdc-logo.svg";
import { useHistory } from "react-router-dom";

export const CryptoSwapReview = () => {
  const history = useHistory();

  const handleSwap = () => {
    history.push(`${process.env.PUBLIC_URL}/crypto-swap-verify`);
  };

  return (
    <>
      <div className="page-layout">
        <div className="ahrvo-card">
          <Link to={`${process.env.PUBLIC_URL}/crypto-home`}>
            <div className="back_navigation">
              <img src={backIcon} alt="" />
            </div>
          </Link>
          <div className="card_header">
            <div className="header_text">
              <h5>Review Your Swap</h5>
            </div>
          </div>
          <div className="primary-swap-review">
            <div className="swap-from-value text-center">
              150 <img className="mx-1" width={18} src={USDTIcon} alt="" />
              USDT
            </div>
            <div className="text-center my-1">
              <img className="opacity-50" src={arrowDown} alt="" />
            </div>
            <div className="swap-to-value text-center">
              <img width={20} src={USDCIcon} alt="" /> USDC
            </div>
            <div className="amount-value-heading mt-1">148.55818</div>
            <div className="amount-value-subheading">
              1 USDT = 0.990387867 USDC{" "}
            </div>
            <hr className="mt-4" />
            <div className="d-flex justify-content-between">
              <div>
                <b>Estimated gas fee</b>
              </div>
              <div>
                0.0034 ETH <b>$3.97</b>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <b>Max fee:</b> $7.93
            </div>
            <hr />
            <div>
              <a href="#">Best of 5 quotes.</a> Includes a 0.743% Metamask fee.
            </div>
          </div>
          <div
            className="primary-button-wallet mt-4"
            onClick={() => handleSwap()}
          >
            Swap
          </div>
          <div className="card_footer">
            <div className="footer_logo_img">
              <img src={logo} alt="" />
            </div>
            <span>Secured by </span>
            <span className="footer_logo">Ahrvo</span>
          </div>
        </div>
      </div>
    </>
  );
};
