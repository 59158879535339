import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

// const auth = localStorage.getItem("accessToken");

const PrivateRoute = ({ exact, component: Component, ...rest }) => {
  return (
    <Route
      exact={exact ? true : false}
      rest
      render={(props) =>
        localStorage.getItem("accessToken") ? (
          <Component {...props} {...rest}></Component>
        ) : (
          <Redirect to={`${process.env.PUBLIC_URL}/`}></Redirect>
        )
      }
    ></Route>
  );
};

export default PrivateRoute;
