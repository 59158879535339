import React, { useState } from "react";
import { Link } from "react-router-dom";
import backIcon from "../../assets/images/arrow_left.svg";
import logo from "../../assets/images/Ahrvo_Network_Logo.png";
import creditCard from "../../assets/images/creditcard-colored.svg";
import impsIcon from "../../assets/images/imps-colored.svg";
import ellipsis from "../../assets/images/ellipsis.svg";
import walletIcon from "../../assets/images/wallet-svgrepo-com.svg";
import editIcon from "../../assets/images/edit-svgrepo-com.svg";
import polygonIcon from "../../assets/images/currency-logo/polygon.svg";
import { useHistory } from "react-router-dom";

export const SwapHome = () => {
  const history = useHistory();
  return (
    <>
      <div className="page-layout">
        <div className="ahrvo-card">
          <Link to={`${process.env.PUBLIC_URL}/MoneyHome`}>
            <div className="back_navigation">
              <img src={backIcon} alt="" />
            </div>
          </Link>
          <div className="card_header">
            <div className="header_text">
              <h5>Swap here</h5>
            </div>
          </div>
          <div
            className="primary-button-wallet mt-5"
            onClick={() =>
              history.push(`${process.env.PUBLIC_URL}/send-money-otp`)
            }
          >
            Review swap
          </div>
          <div className="card_footer">
            <div className="footer_logo_img">
              <img src={logo} alt="" />
            </div>
            <span>Secured by </span>
            <span className="footer_logo">Ahrvo</span>
          </div>
        </div>
      </div>
    </>
  );
};
