import React, { useState } from "react";
import { Link } from "react-router-dom";
import backIcon from "../../../../assets/images/arrow_left.svg";
import logo from "../../../../assets/images/Ahrvo_Network_Logo.png";
import creditCard from "../../../../assets/images/creditcard-colored.svg";
import impsIcon from "../../../../assets/images/imps-colored.svg";
import ellipsis from "../../../../assets/images/ellipsis.svg";
import walletIcon from "../../../../assets/images/wallet-svgrepo-com.svg";
import editIcon from "../../../../assets/images/edit-svgrepo-com.svg";
import polygonIcon from "../../../../assets/images/currency-logo/polygon.svg";
import { useHistory } from "react-router-dom";
import OtpInput from "react-otp-input";
import msgIcon from "../../../../assets/images/msgIcon.svg";
import tickIcon from "../../../../assets/images/tick.svg";
import errorIcon from "../../../../assets/images/error.svg";


export const CryptoSendVerify = () => {
    const [code, setCode] = useState("");
    const [errorVal, setErrorVal] = useState(false);
    const [otpSuccess, setOtpSuccess] = useState(false);
    const [otpFailed, setOtpFailed] = useState(false);
    const history = useHistory();


    const finalOTP = 1234567;

    const handleChange = (code) => {
        setCode(code);
        if (code.length == 7) {
            if (code == finalOTP) {
                setErrorVal("");
                setOtpFailed(false);
                console.log("Success");
                setOtpSuccess(true);
                setTimeout(() => {
                    history.push(`${process.env.PUBLIC_URL}/crypto-home`);
                }, 2000);
            } else {
                setErrorVal(true);
                setOtpSuccess(true);
                setOtpFailed(true);
                setTimeout(() => {
                    history.push(`${process.env.PUBLIC_URL}/crypto-home`);
                }, 2000);
                // localStorage.removeItem("accessToken", "token");

                console.log(otpFailed);
            }
        } else {
            console.log("Failed");
        }
    };

    return (
        <>
            <div className="page-layout">
                <div className="ahrvo-card">
                    <Link to={`${process.env.PUBLIC_URL}/crypto-home`}>
                        <div className="back_navigation">
                            <img src={backIcon} alt="" />
                        </div>
                    </Link>
                    <div className="card_header">
                        <div className="header_text">
                            <h5>Confirm send</h5>
                        </div>
                    </div>
                    <div className="otp-verification-box">
                        <div className="header_logo">
                            <img src={msgIcon} alt="" />
                        </div>
                        <div className="verification-msg">
                            {!otpSuccess
                                ? " Please enter the code we sent to"
                                : !otpFailed
                                    ? "Transaction Successfull"
                                    : "Transaction failed"}

                            <br />
                            <div>ahrvo@info.com</div>
                            {!otpSuccess
                                ? <p>This helps us keep your account secure by verifying that it's really you.</p>
                                : ""}
                        </div>
                        {!otpSuccess ? (
                            <OtpInput
                                value={code}
                                onChange={handleChange}
                                numInputs={7}
                                isInputNum={true}
                                shouldAutoFocus={true}
                                containerStyle="otp-form-group"
                                renderInput={(props) => <input {...props} />}
                            />
                        ) : !otpFailed ? (
                            <div className="d-flex justify-content-center my-4">
                                <img src={tickIcon} alt="" />
                            </div>
                        ) : (
                            <div className="d-flex justify-content-center my-4">
                                <img width={"50px"} src={errorIcon} alt="" />
                            </div>
                        )}
                    </div>
                    {!otpSuccess
                        ? <div className="primary-button-wallet mt-5">Resend Code</div>
                        : ""
                    }
                    <div className="card_footer">
                        <div className="footer_logo_img">
                            <img src={logo} alt="" />
                        </div>
                        <span>Secured by </span>
                        <span className="footer_logo">Ahrvo</span>
                    </div>
                </div>
            </div>
        </>
    );
};
