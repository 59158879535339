import React, { useState, useEffect } from "react";
import logo from "../../assets/images/Ahrvo_Network_Logo.png";
import googleIcon from "../../assets/images/google.svg";
import { useForm } from "react-hook-form";
import msgIcon from "../../assets/images/msgIcon.svg";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import { Form, Spinner, Alert } from "reactstrap";
import infoIcon from "../../assets/images/info.svg";
import backIcon from "../../assets/images/back.svg";
import qrCodeImg from "../../assets/images/qr-codeImg.png";
import faceBookLight from "../../assets/images/social_icons/login-facebook-light.svg";
import faceBookActive from "../../assets/images/social_icons/login-facebook-active.svg";
import redditLight from "../../assets/images/social_icons/login-reddit-light.svg";
import redditActive from "../../assets/images/social_icons/login-reddit-active.svg";
import discordLight from "../../assets/images/social_icons/login-discord-light.svg";
import discordActive from "../../assets/images/social_icons/login-discord-active.svg";
import twitchLight from "../../assets/images/social_icons/login-twitch-light.svg";
import twitchActive from "../../assets/images/social_icons/login-twitch-active.svg";
import appleIcon from "../../assets/images/social_icons/login-apple-light.svg";
import lineLight from "../../assets/images/social_icons/login-line-light.svg";
import lineActive from "../../assets/images/social_icons/login-line-active.svg";
import githubLight from "../../assets/images/social_icons/login-github-light.svg";
import kakaoLight from "../../assets/images/social_icons/login-kakao-light.svg";
import kakaoActive from "../../assets/images/social_icons/login-kakao-active.svg";
import linkedLight from "../../assets/images/social_icons/login-linkedin-light.svg";
import linkedActive from "../../assets/images/social_icons/login-linkedin-active.svg";
import twitterLight from "../../assets/images/social_icons/login-twitter-light.svg";
import twitterActive from "../../assets/images/social_icons/login-twitter-active.svg";
import weiboLight from "../../assets/images/social_icons/login-weibo-light.svg";
import weiboActive from "../../assets/images/social_icons/login-weibo-active.svg";
import wechatLight from "../../assets/images/social_icons/login-wechat-light.svg";
import wechatActive from "../../assets/images/social_icons/login-wechat-active.svg";
import coinBaseIcon from "../../assets/images/social_icons/login-coinbase.svg";
import metaMaskIcon from "../../assets/images/social_icons/login-metamask.svg";
import torusIcon from "../../assets/images/social_icons/login-torus-evm.svg";
import googleLight from "../../assets/images/social_icons/login-google-light.svg";
import googleActive from "../../assets/images/social_icons/login-google-active.svg";
import PrivateRoute from "../../route/PrivateRoute";



export const Login1 = () => {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");
  const [showSocials, setShowSocials] = useState(false);
  const [showExternalWallet, setShowExternalWallet] = useState(false);
  const [submitButton, setSubmitButton] = useState(true);
  const history = useHistory();

  const onFormSubmit = async (formData) => {
    setLoading(true);
    const loginName = "ahrvo@info.com";
    const pass = "9876543210";
    if (formData.name === loginName || pass) {
      localStorage.setItem("accessToken", "token");
      // await setTimeout(() => {
      //   // window.history.pushState(
      //   //   `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
      //   //   "auth-login",
      //   //   `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
      //   // );
      //   window.location.reload();
      // });
      history.push(`${process.env.PUBLIC_URL}/otpVerification`);
    } else {
      setTimeout(() => {
        setError("Cannot login with credentials");
        setLoading(false);
      }, 2000);
    }
  };

  const handleSocialLogin = async () => {
    localStorage.setItem("accessToken", "token");
    console.log("HandleSOcialLoginCalled")
    // await setTimeout(() => {
    //   window.location.reload();
    // });
    history.push(`${process.env.PUBLIC_URL}/otpVerification`);
  };

  const showSocialButton = () => {
    setShowSocials(!showSocials);
  };

  const showExternalWalletFunction = () => {
    setShowExternalWallet(!showExternalWallet);
  };

  const handleSubmitBtn = (e) => {
    if (e.target.value.length == 0) {
      setSubmitButton(true);
    } else {
      setSubmitButton(false);
    }
  };

  const { errors, register, handleSubmit } = useForm();

  return (
    <>
    
      <div className="page-layout">
        <div className="ahrvo-card">
          <div className="card_header">
            <div className="header_text">
              {/* <div>
                Sign in <span>with NFT ID</span>
              </div> */}
            </div>
            <div className="header_logo">
              <img src={logo} alt="logo" />
            </div>
            {/* <p>
              You must be logged in with <span>Email</span>
            </p> */}
          </div>
          {!showExternalWallet ? (
            <div className={showSocials ? "screenAtAllSocial" : ""}>
              <form action="" onSubmit={handleSubmit(onFormSubmit)}>
                <div className="primary-form-group">
                  <label htmlFor="email">
                    Email or Phone
                    <span className="custom-tooltip">
                      <img
                        width={"15px"}
                        className="mx-1 "
                        src={infoIcon}
                        alt=""
                      />
                      <div className="custom-tooltiptext">
                        The wallet serves as an account for you to store and
                        manage your digital assets on the blockchain.
                      </div>
                    </span>
                  </label>
                  <input
                    placeholder="Eg:  +(00)123456/name@example.com"
                    type="text"
                    id="default-01"
                    name="name"
                    ref={register({ required: "This field is required" })}
                    // defaultValue="ahrvo@info.com"
                    className=""
                    onChange={handleSubmitBtn}
                  />
                </div>
                <button
                  type="submit"
                  className=" primary-btn mt-2"
                  disabled={submitButton}
                >
                  <span>
                    {loading ? (
                      <Spinner size="md" className="" color="light" />
                    ) : (
                      "Continue"
                    )}
                  </span>
                </button>
              </form>
              <div className="mt-2 text-center">OR</div>
              <button
                className=" social-btn mt-1"
                area-disabled="true"
                onClick={() => handleSocialLogin()}
              >
                <span className="icon-box">
                  <img width={"24px"} src={logo} alt="" />
                </span>
                <span>NFT ID</span>
              </button>

              <div className="social-login-group">
                <ul className={showSocials ? "allSocialIcons" : ""}>
                  <li onClick={() => handleSocialLogin()}>
                    <img className="imgIcon" src={googleLight} alt="" />
                    <img className="hoverIcon" src={googleActive} alt="" />
                  </li>
                  <li onClick={() => handleSocialLogin()}>
                    <img className="imgIcon" src={faceBookLight} alt="" />
                    <img className="hoverIcon" src={faceBookActive} alt="" />
                  </li>
                  <li onClick={() => handleSocialLogin()}>
                    <img className="imgIcon" src={redditLight} alt="" />
                    <img className="hoverIcon" src={redditActive} alt="" />
                  </li>
                  <li onClick={() => handleSocialLogin()}>
                    <img className="imgIcon" src={discordLight} alt="" />
                    <img className="hoverIcon" src={discordActive} alt="" />
                  </li>
                  <li onClick={() => handleSocialLogin()}>
                    <img className="imgIcon" src={twitchLight} alt="" />
                    <img className="hoverIcon" src={twitchActive} alt="" />
                  </li>
                  <li onClick={() => handleSocialLogin()}>
                    <img className="imgIcon" src={appleIcon} alt="" />
                    <img className="hoverIcon" src={appleIcon} alt="" />
                  </li>
                  <li onClick={() => handleSocialLogin()}>
                    <img className="imgIcon" src={lineLight} alt="" />
                    <img className="hoverIcon" src={lineActive} alt="" />
                  </li>
                  <li onClick={() => handleSocialLogin()}>
                    <img className="imgIcon" src={githubLight} alt="" />
                    <img className="hoverIcon" src={githubLight} alt="" />
                  </li>
                  <li onClick={() => handleSocialLogin()}>
                    <img className="imgIcon" src={kakaoLight} alt="" />
                    <img className="hoverIcon" src={kakaoActive} alt="" />
                  </li>
                  <li onClick={() => handleSocialLogin()}>
                    <img className="imgIcon" src={linkedLight} alt="" />
                    <img className="hoverIcon" src={linkedActive} alt="" />
                  </li>
                  <li onClick={() => handleSocialLogin()}>
                    <img className="imgIcon" src={twitterLight} alt="" />
                    <img className="hoverIcon" src={twitterActive} alt="" />
                  </li>
                  <li onClick={() => handleSocialLogin()}>
                    <img className="imgIcon" src={weiboLight} alt="" />
                    <img className="hoverIcon" src={weiboActive} alt="" />
                  </li>
                  <li onClick={() => handleSocialLogin()}>
                    <img className="imgIcon" src={wechatLight} alt="" />
                    <img className="hoverIcon" src={wechatActive} alt="" />
                  </li>
                </ul>
                <p className="info">
                  We do not store any data related to your social logins.
                </p>
                <p onClick={showSocialButton} className="viewMore">
                  {!showSocials ? "View more options" : "View less options"}{" "}
                </p>
              </div>
              <div className="extrnal-links-box mt-2">
                <p>Extrnal wallet</p>
                <button
                  className=" social-btn mt-1"
                  area-disabled="true"
                  onClick={showExternalWalletFunction}
                >
                  <span>Connect with Wallet</span>
                </button>
              </div>
            </div>
          ) : (
            <div className="external-wallet-area">
              <div className="back-btn" onClick={showExternalWalletFunction}>
                <img src={backIcon} alt="" />
                <span>Back</span>
              </div>
              <div className="qr-code-area">
                <div className="qr-code-msg">
                  Scan QR code with a WalletConnect-compatible wallet
                </div>
                <div className="qr-code-img">
                  <img src={qrCodeImg} alt="" />
                </div>
                <div className="qr-code-msg">
                  Click on the QR Code to copy to clipboard
                </div>
              </div>
              <div className="social-login-group external-wallet mt-3">
                <ul>
                  <li onClick={() => handleSocialLogin()}>
                    <img className="imgIcon" src={coinBaseIcon} alt="" />
                    <img className="hoverIcon" src={coinBaseIcon} alt="" />
                  </li>
                  <li onClick={() => handleSocialLogin()}>
                    <img className="imgIcon" src={metaMaskIcon} alt="" />
                    <img className="hoverIcon" src={metaMaskIcon} alt="" />
                  </li>
                  <li onClick={() => handleSocialLogin()}>
                    <img className="imgIcon" src={torusIcon} alt="" />
                    <img className="hoverIcon" src={torusIcon} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          )}

          <div className="page-links">
            <a href="#">Privacy</a>
            <span className="custom-dot"></span>
            <a href="#">Terms</a>
          </div>
          <div className="card_footer">
            <div className="footer_logo_img">
              <img src={logo} alt="" />
            </div>
            <span>Secured by </span>
            <span className="footer_logo">Ahrvo</span>
          </div>
        </div>
      </div>
    </>
  );
};
