import React from 'react'
import Select from "react-dropdown-select";
import { Link } from 'react-router-dom';
import logo from "../../../../assets/images/Ahrvo_Network_Logo.png";
import backIcon from "../../../../assets/images/arrow_left.svg";

const Countries = [
    { label: "Travel expenses", value: 355 },
    { label: "Property purchase", value: 54 },
    { label: "Payment for software goods", value: 43 },
    { label: "Payment to businesses after collection of goods", value: 61 },
    { label: "Payment to businesses before collection of goods", value: 965 },
    { label: "Payment for software consultancy/services only", value: 46 },
    { label: "Other business services", value: 58 }
];

function CryptoSendSameReason() {
    return (
        <div className='page-layout'>
            <div className="ahrvo-card">
                <Link to={`${process.env.PUBLIC_URL}/crypto-send-same`}>
                    <div className="back_navigation">
                        <img src={backIcon} alt="" />
                    </div>
                </Link>
                <div className="card_header">
                    <div className="header_text">
                        {/* <h6>What's the reason for your transfer?</h6> */}
                    </div>
                </div>
                <h6 className="text-center mb-4">What's the reason for your transfer?</h6>
                <div className=''>
                    {/* <h6 className='text-center transfer-heaadig'>What's the reason for your transfer?</h6> */}
                    <div className='content-text'>To help us keep Ahrvo safe and secure, please let us  know why you're making this transfer. Please note<br /> that FDI(Foreign direct investment) transaction are not allowed.</div>
                    <div className='form-parent'>
                        <form>
                            <div className='form-heading'>Please select an option that best describes the reason for your transfer</div>
                            <Select className='custom-select' options={Countries} isMulti placeholder="Select an option" />
                            <Link to={`${process.env.PUBLIC_URL}/crypto-send-same-review`} className="primary-button-wallet mt-5">Submit</Link>
                        </form>
                    </div>
                </div>
                <div className="card_footer">
                    <div className="footer_logo_img">
                        <img src={logo} alt="" />
                    </div>
                    <span>Secured by </span>
                    <span className="footer_logo">Ahrvo</span>
                </div>
            </div>
        </div>
    )
}

export default CryptoSendSameReason;
