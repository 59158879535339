import React from "react";
import { Link } from "react-router-dom";
import backIcon from "../../assets/images/arrow_left.svg";
import logo from "../../assets/images/Ahrvo_Network_Logo.png";
import creditCard from "../../assets/images/creditcard-colored.svg";
import impsIcon from "../../assets/images/imps-colored.svg";
import ellipsis from "../../assets/images/ellipsis.svg";
import walletIcon from "../../assets/images/wallet-svgrepo-com.svg";
import editIcon from "../../assets/images/edit-svgrepo-com.svg";
import polygonIcon from "../../assets/images/currency-logo/polygon.svg";
import { useHistory } from "react-router-dom";

export const SendReviewPage = () => {
  const history = useHistory();
  return (
    <>
      <div className="page-layout">
        <div className="ahrvo-card">
          <Link to={`${process.env.PUBLIC_URL}/MoneyHome`}>
            <div className="back_navigation">
              <img src={backIcon} alt="" />
            </div>
          </Link>
          <div className="card_header">
            <div className="header_text">
              <h5>Confirm your send</h5>
            </div>
          </div>
          <div className="available-balance-box">
            <h2 className="available-balance">$10.25</h2>
            <p className="balance-copy">12.02899268 MATIC</p>
          </div>
          <div className="send-balance-form-box">
            <div className="send-balance-form-group">
              <label htmlFor="">Pay with</label>
              <div className="send-balance-input-group">
                <div className="icon-img">
                  <img src={polygonIcon} alt="" />
                </div>
                <div className="filled-value">Polygon</div>
              </div>
            </div>
            <div className="send-balance-form-group">
              <label htmlFor="">To</label>
              <div className="send-balance-input-group">
                <div className="icon-img">
                  <img src={walletIcon} alt="" />
                </div>
                <div className="filled-value">0xd1...023a</div>
              </div>
            </div>
            <div className="send-balance-form-group">
              <label htmlFor="">Network</label>
              <div className="send-balance-input-group">
                <div className="filled-value">Ethereum</div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <span>Payment recieved</span>
            <span>est. less than 10 minutes</span>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <span>Network fee</span>
            <span>
              <b>1.02687352 MATIC</b>
            </span>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <span>Total</span>
            <span>
              <b>$11.13</b>
            </span>
          </div>
          <div
            className="primary-button-wallet mt-5"
            onClick={() =>
              history.push(`${process.env.PUBLIC_URL}/send-money-otp`)
            }
          >
            Send now
          </div>
          <div className="card_footer">
            <div className="footer_logo_img">
              <img src={logo} alt="" />
            </div>
            <span>Secured by </span>
            <span className="footer_logo">Ahrvo</span>
          </div>
        </div>
      </div>
    </>
  );
};
