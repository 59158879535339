import React, { useEffect, useState } from 'react'
import {
  ReactDataTable, Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  TooltipComponent,
  Row,
  Col,
  OverlineTitle,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  RSelect,
} from '../../../components/Component';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  ModalBody,
  Modal,
  DropdownItem,
  Form,
  Badge,
} from "reactstrap";
import { orderData } from '../../../components/table/TableData'
import { dataTableColumns2, userData } from '../../components/table/TableData'
import { cryptoTransData, cryptoColumnData } from './CryptoTableData'
import { useForm } from "react-hook-form";
import { cryptoActivityOptions, filterStatusOptions } from '../../pre-built/trans-list/TransData';


export const CryptoTransList = () => {
  const [modal, setModal] = useState({
    add: false,
  });
  const [data, setData] = useState(cryptoTransData);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const [formData, setFormData] = useState({
    orderType: "Deposit",
    amountBTC: "",
    amountUSD: "",
    balanceBTC: "",
    balanceUSD: "",
    status: "Pending",
  });

  const { errors, register, handleSubmit } = useForm();
  // function to close the form modal
  const onFormCancel = () => {
    setModal({ add: false });
    resetForm();
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      orderType: "Buy",
      amountBTC: "",
      amountUSD: "",
      balanceBTC: "",
      balanceUSD: "",
      status: "Pending",
    });
  };


  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.ref.localeCompare(b.ref));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.ref.localeCompare(a.ref));
      setData([...sortedData]);
    }
  };

  // submit function to add a new item
  const onFormSubmit = (submitData) => {
    const { amountBTC, amountUSD, balanceUSD, balanceBTC } = submitData;
    let submittedData = {
      id: data.length + 1,
      ref: "YWLX52JG73",
      date: "18/10/2019 12:04 PM",
      desc: formData.orderType === "Profit" ? "Credited " + formData.orderType : formData.orderType + " Funds",
      orderType: formData.orderType,
      amountBTC: amountBTC,
      amountUSD: amountUSD,
      balanceBTC: balanceBTC,
      balanceUSD: balanceUSD,
      status: formData.status,
    };
    setData([submittedData, ...data]);
    resetForm();
    setModal({ add: false });
  };

  // function to toggle the search option
  // const toggle = () => setonSearch(!onSearch);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = cryptoTransData.filter((item) => {
        return item.ref.toLowerCase().includes(onSearchText.toLowerCase());
      });
      setData([...filteredObject]);
    } else {
      setData([...orderData]);
    }
  }, [onSearchText]);

  return (
    <div>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle page>Transaction</BlockTitle>
            <BlockDes className="text-soft">
              <p>You have total 12,835 orders.</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent>
            <ul className="nk-block-tools g-3">
              <li>
                <Button color="light" outline className="btn-white">
                  <Icon name="download-cloud"></Icon>
                  <span>Export</span>
                </Button>
              </li>
              <li>
                <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                  <Icon name="plus"></Icon>
                </Button>
              </li>
            </ul>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <div className='card-bordered card-stretch card'>
          <div className='card-inner-group'>
            {/* <div className="card-inner">
              <div className="card-title-group">
                <div className="card-title">
                  <h5 className="title">All Orders</h5>
                </div>
                <div className="card-tools me-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <Button
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </Button>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                          <div className="dot dot-primary"></div>
                          <Icon name="filter-alt"></Icon>
                        </DropdownToggle>
                        <DropdownMenu end className="filter-wg dropdown-menu-xl">
                          <div className="dropdown-head">
                            <span className="sub-title dropdown-title">Advanced Filter</span>
                            <div className="dropdown">
                              <Button size="sm" className="btn-icon">
                                <Icon name="more-h"></Icon>
                              </Button>
                            </div>
                          </div>
                          <div className="dropdown-body dropdown-body-rg">
                            <Row className="gx-6 gy-4">
                              <Col size="6">
                                <div className="form-group">
                                  <label className="overline-title overline-title-alt">Type</label>
                                  <RSelect options={cryptoActivityOptions} placeholder="Any Activity" />
                                </div>
                              </Col>
                              <Col size="6">
                                <div className="form-group">
                                  <label className="overline-title overline-title-alt">Status</label>
                                  <RSelect options={filterStatusOptions} placeholder="Any Status" />
                                </div>
                              </Col>

                              <Col size="6">
                                <div className="form-group">
                                  <div className="custom-control custom-control-sm custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="includeDel" />
                                    <label className="custom-control-label" htmlFor="includeDel">
                                      {" "}
                                      Including Deleted
                                    </label>
                                  </div>
                                </div>
                              </Col>

                              <Col size="12">
                                <div className="form-group">
                                  <Button type="button" className="btn btn-secondary">
                                    Filter
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="dropdown-foot between">
                            <a
                              href="#reset"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                              className="clickable"
                            >
                              Reset Filter
                            </a>
                            <a
                              href="#save"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              Save Filter
                            </a>
                          </div>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                          <Icon name="setting"></Icon>
                        </DropdownToggle>
                        <DropdownMenu end className="dropdown-menu-xs">
                          <ul className="link-check">
                            <li>
                              <span>Show</span>
                            </li>
                            <li className={itemPerPage === 10 ? "active" : ""}>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setItemPerPage(10);
                                }}
                              >
                                10
                              </DropdownItem>
                            </li>
                            <li className={itemPerPage === 15 ? "active" : ""}>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setItemPerPage(15);
                                }}
                              >
                                15
                              </DropdownItem>
                            </li>
                          </ul>
                          <ul className="link-check">
                            <li>
                              <span>Order</span>
                            </li>
                            <li className={sort === "dsc" ? "active" : ""}>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setSortState("dsc");
                                  sortFunc("dsc");
                                }}
                              >
                                DESC
                              </DropdownItem>
                            </li>
                            <li className={sort === "asc" ? "active" : ""}>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setSortState("asc");
                                  sortFunc("asc");
                                }}
                              >
                                ASC
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </ul>
                </div>
                <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                  <div className="search-content">
                    <Button
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                      className="search-back btn-icon toggle-search"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by Order Id"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div> */}
            <ReactDataTable
              data={cryptoTransData}
              columns={cryptoColumnData}
              className="card-stretch custom-transaction-table"
              pagination
            />
          </div>
        </div>
      </Block>

      <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <a
            href="#close"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Add Transaction</h5>
            <Form className="mt-4" onSubmit={handleSubmit(onFormSubmit)} noValidate>
              <Row className="g-gs">
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Order Type</label>
                    <div className="form-control-wrap">
                      <RSelect
                        options={cryptoActivityOptions}
                        defaultValue={[{ value: "deposit", label: "Deposit" }]}
                        onChange={(e) => setFormData({ ...formData, orderType: e.value })}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Status</label>
                    <div className="form-control-wrap">
                      <RSelect
                        options={filterStatusOptions}
                        defaultValue={[{ value: "Pending", label: "Pending" }]}
                        onChange={(e) => setFormData({ ...formData, status: e.value })}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <OverlineTitle className="pt-4"> Amount </OverlineTitle>
              <hr className="hr mt-2 border-light" />
              <Row className="g-gs">
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">BTC</label>
                    <input
                      type="number"
                      name="amountBTC"
                      defaultValue={formData.amountBTC}
                      className="form-control"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.amountBTC && <span className="invalid">{errors.amountBTC.message}</span>}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">USD</label>
                    <input
                      type="number"
                      name="amountUSD"
                      defaultValue={formData.amountUSD}
                      className="form-control"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.amountUSD && <span className="invalid">{errors.amountUSD.message}</span>}
                  </div>
                </Col>
              </Row>
              <OverlineTitle className="pt-4"> Balance </OverlineTitle>
              <hr className="hr mt-2 border-light" />
              <Row className="gy-4">
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">BTC</label>
                    <input
                      type="number"
                      name="balanceBTC"
                      defaultValue={formData.balanceBTC}
                      className="form-control"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.balanceBTC && <span className="invalid">{errors.balanceBTC.message}</span>}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">USD</label>
                    <input
                      type="number"
                      name="balanceUSD"
                      defaultValue={formData.balanceUSD}
                      className="form-control"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.balanceUSD && <span className="invalid">{errors.balanceUSD.message}</span>}
                  </div>
                </Col>
                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button type="submit" color="primary" size="md">
                        Add Transaction
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Form>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}
