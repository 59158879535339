import React from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import Logo from "../logo/Logo";
import News from "../news/News";
import User from "./dropdown/user/User";
import Notification from "./dropdown/notification/Notification";
import LanguageHead from "./dropdown/language/Language";

const Header = ({ fixed, theme, className, setVisibility, ...props }) => {
  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme === "white",
    [`is-${theme}`]: theme !== "white" && theme !== "light",
    [`${className}`]: className,
  });

  const handleCollapseSidebar =()=> {
    document.body.classList.toggle("collapse_sidebar")
  }

  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="">
          {/* <div className="side_collapser p-0">
            <em class="icon ni ni-menu-left" onClick={()=> handleCollapseSidebar()}></em>
          </div> */}
          <div className="nk-header-wrap">
            <div className="nk-menu-trigger d-xxl-none ms-n1">
              <Toggle
                className="nk-nav-toggle nk-quick-nav-icon cursor_pntr ms-n1"
                icon="menu"
                click={props.sidebarToggle}
              />
            </div>
            <div className="nk-header-brand d-xxl-none">
              <Logo />
            </div>
            <div className="nk-header-news d-none d-xxl-block">
              <News />
            </div>
            <div className="nk-header-tools">
              <ul className="nk-quick-nav">
                <li className="language-dropdown d-none d-sm-block me-n1"  onClick={() => setVisibility(false)}>
                  <LanguageHead />
                </li>
                <li className="user-dropdown" onClick={() => setVisibility(false)}>
                  <User />
                </li>
                <li className="notification-dropdown me-n1" onClick={() => setVisibility(false)}>
                  <Notification />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
