export var refBarChart = {
  labels: [
    "01 Nov",
    "02 Nov",
    "03 Nov",
    "04 Nov",
    "05 Nov",
    "06 Nov",
    "07 Nov",
    "08 Nov",
    "09 Nov",
    "10 Nov",
    "11 Nov",
    "12 Nov",
    "13 Nov",
    "14 Nov",
    "15 Nov",
    "16 Nov",
    "17 Nov",
    "18 Nov",
    "19 Nov",
    "20 Nov",
    "21 Nov",
    "22 Nov",
    "23 Nov",
    "24 Nov",
    "25 Nov",
    "26 Nov",
    "27 Nov",
    "28 Nov",
    "29 Nov",
    "30 Nov",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Join",
      backgroundColor: "#9cabff",
      borderWidth: 2,
      borderColor: "transparent",
      hoverBorderColor: "transparent",
      borderSkipped: "bottom",
      barPercentage: 0.5,
      categoryPercentage: 0.7,
      data: [
        110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95,
        75, 90, 75, 90,
      ],
    },
  ],
};

export var refBarChartSet2 = {
  labels: [
    "01 Nov",
    "02 Nov",
    "03 Nov",
    "04 Nov",
    "05 Nov",
    "06 Nov",
    "07 Nov",
    "08 Nov",
    "09 Nov",
    "10 Nov",
    "11 Nov",
    "12 Nov",
    "13 Nov",
    "14 Nov",
    "15 Nov",
    "16 Nov",
    "17 Nov",
    "18 Nov",
    "19 Nov",
    "20 Nov",
    "21 Nov",
    "22 Nov",
    "23 Nov",
    "24 Nov",
    "25 Nov",
    "26 Nov",
    "27 Nov",
    "28 Nov",
    "29 Nov",
    "30 Nov",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Join",
      backgroundColor: "#9cabff",
      borderWidth: 2,
      borderColor: "transparent",
      hoverBorderColor: "transparent",
      borderSkipped: "bottom",
      barPercentage: 0.5,
      categoryPercentage: 0.7,
      data: [
        90, 150, 105, 55, 95, 75, 90, 110, 80, 125, 105, 95, 25, 90, 110, 80, 125, 55, 105, 75, 40, 110, 150, 125, 55,
        45, 75, 90, 75, 150,
      ],
    },
  ],
};

export var refBarChartSet3 = {
  labels: [
    "01 Nov",
    "02 Nov",
    "03 Nov",
    "04 Nov",
    "05 Nov",
    "06 Nov",
    "07 Nov",
    "08 Nov",
    "09 Nov",
    "10 Nov",
    "11 Nov",
    "12 Nov",
    "13 Nov",
    "14 Nov",
    "15 Nov",
    "16 Nov",
    "17 Nov",
    "18 Nov",
    "19 Nov",
    "20 Nov",
    "21 Nov",
    "22 Nov",
    "23 Nov",
    "24 Nov",
    "25 Nov",
    "26 Nov",
    "27 Nov",
    "28 Nov",
    "29 Nov",
    "30 Nov",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Join",
      backgroundColor: "#9cabff",
      borderWidth: 2,
      borderColor: "transparent",
      hoverBorderColor: "transparent",
      borderSkipped: "bottom",
      barPercentage: 0.5,
      categoryPercentage: 0.7,
      data: [
        150, 90, 25, 115, 115, 75, 150, 30, 70, 135, 85, 55, 45, 90, 90, 70, 135, 75, 55, 55, 90, 120, 20, 135, 55, 95,
        75, 90, 55, 90,
      ],
    },
  ],
};

export var refBarChart4 = {
  labels: [
    "01 Nov",
    "02 Nov",
    "03 Nov",
    "04 Nov",
    "05 Nov",
    "06 Nov",
    "07 Nov",
    "08 Nov",
    "09 Nov",
    "10 Nov",
    "11 Nov",
    "12 Nov",
    "13 Nov",
    "14 Nov",
    "15 Nov",
    "16 Nov",
    "17 Nov",
    "18 Nov",
    "19 Nov",
    "20 Nov",
    "21 Nov",
    "22 Nov",
    "23 Nov",
    "24 Nov",
    "25 Nov",
    "26 Nov",
    "27 Nov",
    "28 Nov",
    "29 Nov",
    "30 Nov",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Join",
      backgroundColor: "#9cabff",
      borderWidth: 2,
      borderColor: "transparent",
      hoverBorderColor: "transparent",
      borderSkipped: "bottom",
      barPercentage: 0.5,
      categoryPercentage: 0.7,
      data: [
        78, 80, 105, 75, 55, 55, 50, 100, 110, 85, 75, 45, 55, 10, 110, 80, 125, 55, 95, 75, 90, 110, 70, 105, 125, 105,
        75, 90, 55, 120,
      ],
    },
  ],
};

export var mainBalance = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  dataUnit: "BTC",
  lineTension: 0.4,
  datasets: [
    {
      label: "Send",
      borderColor: "#3a8dfe",
      borderWidth: 2,
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#3a8dfe",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      backgroundColor: "transparent",
      lineTension: 0.4,
      data: [
        110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95,
        75, 90, 75, 90,
      ],
    },
    {
      label: "Receive",
      borderColor: "#5ce0aa",
      pointHoverBackgroundColor: "#fff",
      pointBackgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointHoverBorderColor: "#5ce0aa",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      backgroundColor: "transparent",
      lineTension: 0.4,
      borderWidth: 2,
      data: [
        80, 54, 105, 120, 82, 85, 60, 80, 54, 105, 120, 82, 85, 60, 80, 54, 105, 120, 82, 85, 60, 80, 54, 105, 120, 82,
        85, 60, 85, 60,
      ],
    },
    {
      label: "Withdraw",
      borderColor: "#f6ca3e",
      pointHoverBackgroundColor: "#fff",
      pointBorderColor: "transparent",
      pointHoverBorderColor: "#f6ca3e",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      backgroundColor: "transparent",
      lineTension: 0.4,
      borderWidth: 2,
      data: [
        90, 98, 115, 70, 87, 95, 67, 90, 98, 115, 70, 87, 95, 67, 90, 98, 115, 70, 87, 95, 67, 90, 98, 115, 70, 87, 95,
        67, 95, 67,
      ],
    },
  ],
};

export var mainBalanceSet2 = {
  labels: [
    "01 Nov",
    "02 Nov",
    "03 Nov",
    "04 Nov",
    "05 Nov",
    "06 Nov",
    "07 Nov",
    "08 Nov",
    "09 Nov",
    "10 Nov",
    "11 Nov",
    "12 Nov",
    "13 Nov",
    "14 Nov",
    "15 Nov",
    "16 Nov",
    "17 Nov",
    "18 Nov",
    "19 Nov",
    "20 Nov",
    "21 Nov",
    "22 Nov",
    "23 Nov",
    "24 Nov",
    "25 Nov",
    "26 Nov",
    "27 Nov",
    "28 Nov",
    "29 Nov",
    "30 Nov",
  ],
  dataUnit: "BTC",
  datasets: [
    {
      label: "Send",
      borderColor: "#3a8dfe",
      pointHoverBackgroundColor: "#fff",
      pointBackgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointHoverBorderColor: "#3a8dfe",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      backgroundColor: "transparent",
      lineTension: 0.4,
      borderWidth: 2,
      data: [
        10, 50, 15, 55, 45, 85, 40, 10, 110, 55, 105, 15, 55, 80, 110, 80, 125, 55, 95, 75, 90, 110, 80, 105, 15, 15,
        125, 80, 75, 10,
      ],
    },
    {
      label: "Receive",
      borderColor: "#5ce0aa",
      pointHoverBackgroundColor: "#fff",
      pointBackgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointHoverBorderColor: "#5ce0aa",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      backgroundColor: "transparent",
      lineTension: 0.4,
      borderWidth: 2,
      data: [
        80, 54, 105, 120, 82, 85, 60, 80, 54, 105, 120, 82, 85, 60, 80, 54, 105, 120, 82, 85, 60, 80, 54, 105, 120, 82,
        85, 60, 85, 60,
      ],
    },
    {
      label: "Withdraw",
      borderColor: "#f6ca3e",
      pointHoverBackgroundColor: "#fff",
      pointBackgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointHoverBorderColor: "#f6ca3e",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      backgroundColor: "transparent",
      lineTension: 0.4,
      borderWidth: 2,
      data: [
        110, 15, 105, 50, 57, 95, 67, 90, 98, 115, 70, 87, 95, 67, 90, 58, 105, 70, 27, 65, 57, 100, 28, 28, 110, 120,
        100, 67, 95, 67,
      ],
    },
  ],
};

export var mainBalanceSet3 = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  dataUnit: "BTC",
  datasets: [
    {
      label: "Send",
      borderColor: "#3a8dfe",
      pointHoverBackgroundColor: "#fff",
      pointBackgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointHoverBorderColor: "#3a8dfe",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      backgroundColor: "transparent",
      lineTension: 0.4,
      borderWidth: 2,
      data: [
        25, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95,
        75, 90, 75, 90,
      ],
    },
    {
      label: "Receive",
      borderColor: "#5ce0aa",
      pointHoverBackgroundColor: "#fff",
      pointBackgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointHoverBorderColor: "#5ce0aa",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      backgroundColor: "transparent",
      lineTension: 0.4,
      borderWidth: 2,
      data: [
        80, 54, 105, 120, 82, 85, 60, 80, 54, 105, 120, 82, 85, 60, 80, 54, 105, 120, 82, 85, 60, 80, 54, 105, 120, 82,
        85, 60, 85, 60,
      ],
    },
    {
      label: "Withdraw",
      borderColor: "#f6ca3e",
      pointHoverBackgroundColor: "#fff",
      pointBackgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointHoverBorderColor: "#f6ca3e",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      backgroundColor: "transparent",
      lineTension: 0.4,
      borderWidth: 2,
      data: [
        90, 98, 115, 70, 87, 95, 67, 90, 98, 115, 70, 87, 95, 67, 90, 98, 115, 70, 87, 95, 67, 90, 98, 115, 70, 87, 95,
        67, 95, 67,
      ],
    },
  ],
};

export var availableBalance = {
  labels: [
    "01 Nov",
    "02 Nov",
    "03 Nov",
    "04 Nov",
    "05 Nov",
    "06 Nov",
    "07 Nov",
    "08 Nov",
    "09 Nov",
    "10 Nov",
    "11 Nov",
    "12 Nov",
    "13 Nov",
    "14 Nov",
    "15 Nov",
    "16 Nov",
    "17 Nov",
    "18 Nov",
    "19 Nov",
    "20 Nov",
    "21 Nov",
    "22 Nov",
    "23 Nov",
    "24 Nov",
    "25 Nov",
    "26 Nov",
    "27 Nov",
    "28 Nov",
    "29 Nov",
    "30 Nov",
  ],
  dataUnit: "BTC",
  datasets: [
    {
      label: "Send",
      backgroundColor: "#6baafe",
      borderWidth: 2,
      borderColor: "transparent",
      hoverBorderColor: "transparent",
      borderSkipped: "bottom",
      barPercentage: 0.6,
      categoryPercentage: 0.7,
      data: [
        110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95,
        75, 90, 75, 90,
      ],
    },
    {
      label: "Receive",
      backgroundColor: "#baaeff",
      borderWidth: 2,
      borderColor: "transparent",
      hoverBorderColor: "transparent",
      borderSkipped: "bottom",
      barPercentage: 0.6,
      categoryPercentage: 0.7,
      data: [
        80, 54, 105, 120, 82, 85, 60, 80, 54, 105, 120, 82, 85, 60, 80, 54, 105, 120, 82, 85, 60, 80, 54, 105, 120, 82,
        85, 60, 85, 60,
      ],
    },
    {
      label: "Withdraw",
      backgroundColor: "#a7ccff",
      borderWidth: 2,
      borderColor: "transparent",
      hoverBorderColor: "transparent",
      borderSkipped: "bottom",
      barPercentage: 0.6,
      categoryPercentage: 0.7,
      data: [
        90, 98, 115, 70, 87, 95, 67, 90, 98, 115, 70, 87, 95, 67, 90, 98, 115, 70, 87, 95, 67, 90, 98, 115, 70, 87, 95,
        67, 95, 67,
      ],
    },
  ],
};

export var summaryBalance = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  dataUnit: "BTC",
  datasets: [
    {
      label: "Total Received",
      borderColor: "#5ce0aa",
      tension: 0.4,
      backgroundColor: "transparent",
      borderWidth: 2,
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#5ce0aa",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95],
    },
    {
      label: "Total Send",
      borderColor: "#3a8dfe",
      tension: 0.4,
      backgroundColor: "transparent",
      borderWidth: 2,
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#3a8dfe",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [80, 54, 105, 120, 82, 85, 60, 80, 54, 105, 120, 82],
    },
    {
      label: "Total Withdraw",
      borderColor: "#f6ca3e",
      tension: 0.4,
      backgroundColor: "transparent",
      borderWidth: 2,
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#f6ca3e",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [90, 98, 115, 70, 87, 95, 67, 90, 98, 115, 70, 87],
    },
  ],
};

export var summaryBalanceMonth = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  dataUnit: "BTC",
  datasets: [
    {
      label: "Total Received",
      borderColor: "#5ce0aa",
      tension: 0.4,
      backgroundColor: "transparent",
      borderWidth: 2,
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#5ce0aa",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [20, 50, 15, 85, 25, 55, 70, 100, 80, 125, 25, 35],
    },
    {
      label: "Total Send",
      borderColor: "#3a8dfe",
      tension: 0.4,
      backgroundColor: "transparent",
      borderWidth: 2,
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#3a8dfe",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [20, 34, 55, 10, 52, 25, 60, 80, 44, 105, 120, 120],
    },
    {
      label: "Total Withdraw",
      borderColor: "#f6ca3e",
      tension: 0.4,
      backgroundColor: "transparent",
      borderWidth: 2,
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#f6ca3e",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [125, 28, 85, 90, 127, 55, 47, 60, 28, 85, 60, 87],
    },
  ],
};

export var summaryBalanceYear = {
  labels: ["2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021"],
  dataUnit: "BTC",
  datasets: [
    {
      label: "Total Received",
      borderColor: "#5ce0aa",
      tension: 0.4,
      backgroundColor: "transparent",
      borderWidth: 2,
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#5ce0aa",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95],
    },
    {
      label: "Total Send",
      borderColor: "#3a8dfe",
      tension: 0.4,
      backgroundColor: "transparent",
      borderWidth: 2,
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#3a8dfe",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [80, 54, 105, 120, 82, 85, 60, 80, 54, 105, 120, 82],
    },
    {
      label: "Total Withdraw",
      borderColor: "#f6ca3e",
      tension: 0.4,
      backgroundColor: "transparent",
      borderWidth: 2,
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#f6ca3e",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [90, 98, 115, 70, 87, 95, 67, 90, 98, 115, 70, 87],
    },
  ],
};

export var summaryBalanceCurrent = {
  labels: [
    "01 Nov",
    "02 Nov",
    "03 Nov",
    "04 Nov",
    "05 Nov",
    "06 Nov",
    "07 Nov",
    "08 Nov",
    "09 Nov",
    "10 Nov",
    "11 Nov",
    "12 Nov",
    "13 Nov",
    "14 Nov",
    "15 Nov",
    "16 Nov",
    "17 Nov",
    "18 Nov",
    "19 Nov",
    "20 Nov",
    "21 Nov",
    "22 Nov",
    "23 Nov",
    "24 Nov",
    "25 Nov",
    "26 Nov",
    "27 Nov",
    "28 Nov",
    "29 Nov",
    "30 Nov",
  ],
  dataUnit: "BTC",
  datasets: [
    {
      label: "Total Received",
      borderColor: "#5ce0aa",
      tension: 0.4,
      backgroundColor: "transparent",
      borderWidth: 2,
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#5ce0aa",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [
        110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95, 110, 80, 125, 55, 95, 110, 80, 125, 55, 95, 110, 80, 125,
        55, 95, 110, 80, 125, 55, 95,
      ],
    },
    {
      label: "Total Send",
      borderColor: "#3a8dfe",
      tension: 0.4,
      backgroundColor: "transparent",
      borderWidth: 2,
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#3a8dfe",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [
        80, 54, 105, 120, 82, 85, 60, 80, 54, 105, 120, 82, 80, 54, 105, 120, 82, 85, 60, 80, 54, 105, 120, 82, 80, 54,
        105, 120, 82, 85, 60, 80, 54, 105, 120, 82,
      ],
    },
    {
      label: "Total Withdraw",
      borderColor: "#f6ca3e",
      tension: 0.4,
      backgroundColor: "transparent",
      borderWidth: 2,
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#f6ca3e",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [
        90, 98, 115, 70, 87, 95, 67, 90, 98, 115, 70, 87, 90, 98, 115, 70, 87, 95, 67, 90, 98, 115, 70, 87, 90, 98, 115,
        70, 87, 95, 67, 90, 98, 115, 70, 87,
      ],
    },
  ],
};
