import React, { useState } from "react";
import logo from "../../../../assets/images/Ahrvo_Network_Logo.png";
import arrowDown from "../../../../assets/images/arrow_down.svg";
import sendIcon from "../../../../assets/images/sendIcon.svg";
import recieveIcon from "../../../../assets/images/recieveIcon.svg";
import polygonIcon from "../../../../assets/images/currency-logo/polygon.svg";
import bankIcon from "../../../../assets/images/currency-logo/bank.svg";
import sideArrowIcon from "../../../../assets/images/arrow_right.svg";
import bankCardIcon from "../../../../assets/images/currency-logo/bankCard.svg";
import applePay from "../../../../assets/images/currency-logo/applePay.svg";
import googlePayIcon from "../../../../assets/images/currency-logo/googlePay.svg";
import linkIcon from "../../../../assets/images/linkIcon.svg";
import backIcon from "../../../../assets/images/arrow_left.svg";
import arrowDownCaret from "../../../../assets/images/arrow_down_caret.svg";
import sardineIcon from "../../../../assets/images/currency-logo/sardine.svg";
import swapIcon from "../../../../assets/images/swap-svgrepo-com.svg";
import coinify from "../../../../assets/images/currency-logo/Nium-300x156.png";
import transakLogo from "../../../../assets/images/currency-logo/convera-300x150.png";
import SGVeteriesLogo from "../../../../assets/images/currency-logo/thunes-300x66.png";
import { Link } from "react-router-dom";

export const FiatProviders = () => {
  const [showProiderBox, setShowProviderBox] = useState(false);

  const handleBuyMethod = (e) => {
    e.preventDefault();
    setShowProviderBox(false);
  };

  return (
    <>
      <div className="page-layout">
        <div className="ahrvo-card">
          <div
            className={`backdrop-layer ${showProiderBox ? "hide" : ""}`}
            onClick={() => setShowProviderBox(!showProiderBox)}
          ></div>
         
          
          {/* <div className="selected-currency">
            <div className="currency-text-side">
              <div className="currency-logo">
                <img src={polygonIcon} alt="" />
              </div>
              <div className="currency-name">Polygon</div>
            </div>
            <div className="currency-value-side text-center">
              <p className="currency_value">$0.00</p>
              <p className="currency_value_text">0 MATIC</p>
            </div>
          </div> */}

          <div
            className={`provider-selection-box h-100 ${showProiderBox ? "hide-selection-box" : ""
              }`}
          >
             <Link to={`${process.env.PUBLIC_URL}/fiat-deposit-method-payment`}>
                <div className="back_navigation">
                <img src={backIcon} alt="" />
                </div>
            </Link>
            <div className="provider-selection-header mt-5">
              <div className="provider-heading">Choose a provider</div>
              
            </div>
            <div className="provider-options">
              <Link
                className="provider-option"
                to={`${process.env.PUBLIC_URL}/fiat-buy`}
              >
                <div className="provider-option-left">
                  <div className="provider-icon">
                    <img src={coinify} alt="" />
                  </div>
                </div>
                <div className="provider-text">$3,000 daily limit</div>
              </Link>
              <Link
                className="provider-option"
                to={`${process.env.PUBLIC_URL}/fiat-buy`}
              >
                <div className="provider-option-left">
                  <div className="provider-icon">
                    <img src={transakLogo} alt="" />
                  </div>
                </div>
                <div className="provider-text">Limits vary</div>
              </Link>
              <div className="provider-option">
                <div className="provider-option-left">
                  <div className="provider-icon">
                    <img src={SGVeteriesLogo} alt="" />
                  </div>
                </div>
                <div className="provider-text">$3,000 daily limit</div>
              </div>
            </div>
          </div>

          <div className="card_footer">
            <div className="footer_logo_img">
              <img src={logo} alt="" />
            </div>
            <span>Secured by </span>
            <span className="footer_logo">Ahrvo</span>
          </div>
        </div>
      </div>
    </>
  );
};
