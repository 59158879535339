import React, { useState } from "react";
import logo from "../../../assets/images/Ahrvo_Network_Logo.png";
import arrowDown from "../../../assets/images/arrow_down.svg";
import sendIcon from "../../../assets/images/sendIcon.svg";
import recieveIcon from "../../../assets/images/recieveIcon.svg";
import polygonIcon from "../../../assets/images/currency-logo/polygon.svg";
import bankIcon from "../../../assets/images/currency-logo/bank.svg";
import sideArrowIcon from "../../../assets/images/arrow_right.svg";
import bankCardIcon from "../../../assets/images/currency-logo/bankCard.svg";
import applePay from "../../../assets/images/currency-logo/applePay.svg";
import googlePayIcon from "../../../assets/images/currency-logo/googlePay.svg";
import linkIcon from "../../../assets/images/linkIcon.svg";
import backIcon from "../../../assets/images/arrow_left.svg";
import arrowDownCaret from "../../../assets/images/arrow_down_caret.svg";
import sardineIcon from "../../../assets/images/currency-logo/sardine.svg";
import swapIcon from "../../../assets/images/swap-svgrepo-com.svg";
import { Link } from "react-router-dom";
import coinify from "../../../assets/images/currency-logo/coinify.svg";
import transakLogo from "../../../assets/images/currency-logo/transak-logo.svg";
import SGVeteriesLogo from "../../../assets/images/currency-logo/sgveteries.svg";

export const CryptoHome = () => {
    const [showProiderBox, setShowProviderBox] = useState(true);

    const handleBuyMethod = (e) => {
        e.preventDefault();
        setShowProviderBox(false);
    }

    return (
        <>
            <div className="page-layout">
                <div className="ahrvo-card">
                    <div
                        className={`backdrop-layer ${showProiderBox ? "hide" : ""}`}
                        onClick={() => setShowProviderBox(!showProiderBox)}
                    ></div>
                    <Link to={`${process.env.PUBLIC_URL}/crypto`}>
                        <div className="back_navigation">
                            <img src={backIcon} alt="" />
                        </div>
                    </Link>
                    <div className="card_header">
                        <h6 className="text-center">Crypto</h6>
                        <div className="header_text">
                            {/* <div>Polygon</div> */}
                        </div>
                        {/* <div className="header_logo">
                            <img src={logo} alt="logo" />
                        </div> */}
                    </div>

                    <div className="available-balance-box">
                        <h2 className="available-balance">$0.00</h2>
                        <p className="balance-copy">0xcbf...26f8</p>
                    </div>
                    <div className="action-buttons-money">
                        <div className="action_button">
                            <Link to={`${process.env.PUBLIC_URL}/crypto-payment-method`} onClick={(e) => handleBuyMethod(e)}>
                                <span>
                                    <img src={arrowDown} alt="" />
                                </span>
                                <p>Buy</p>
                            </Link>
                        </div>
                        <div className="action_button">
                            <Link to={`${process.env.PUBLIC_URL}/crypto-send`} >
                                <span>
                                    <img src={sendIcon} alt="" />
                                </span>
                                <p>Send</p>
                            </Link>
                        </div>
                        <div className="action_button">
                            <Link to={`${process.env.PUBLIC_URL}/crypto-recieve`}>
                                <span>
                                    <img src={recieveIcon} alt="" />
                                </span>
                                <p>Receive</p>
                            </Link>
                        </div>
                        <div className="action_button">
                            <Link
                                to={`${process.env.PUBLIC_URL}/crypto-swap`}
                            >
                                <span>
                                    <img src={swapIcon} alt="" />
                                </span>
                                <p>Swap</p>
                            </Link>
                        </div>
                    </div>
                    <div className="selected-currency">
                        <div className="currency-text-side">
                            <div className="currency-logo">
                                <img src={polygonIcon} alt="" />
                            </div>
                            <div className="currency-name">Polygon</div>
                        </div>
                        <div className="currency-value-side text-center">
                            <p className="currency_value">$0.00</p>
                            <p className="currency_value_text">0 MATIC</p>
                        </div>
                    </div>

                    <div
                        className={`provider-selection-box ${showProiderBox ? "hide-selection-box" : ""
                            }`}
                    >
                        <div className="provider-selection-header">
                            <div className="provider-heading">Choose a provider</div>
                            <div
                                className="close-down-arrow"
                                onClick={() => setShowProviderBox(!showProiderBox)}
                            >
                                <img src={arrowDownCaret} alt="" />
                            </div>
                        </div>
                        <div className="provider-options">
                            <Link
                                className="provider-option"
                                to={`${process.env.PUBLIC_URL}/crypto-buy`}
                            >
                                <div className="provider-option-left">
                                    <div className="provider-icon">
                                        <img src={coinify} alt="" />
                                    </div>
                                </div>
                                <div className="provider-text">$3,000 daily limit</div>
                            </Link>
                            <Link
                                className="provider-option"
                                to={`${process.env.PUBLIC_URL}/buyMatic`}
                            >
                                <div className="provider-option-left">
                                    <div className="provider-icon">
                                        <img src={transakLogo} alt="" />
                                    </div>
                                </div>
                                <div className="provider-text">Limits vary</div>
                            </Link>
                            <div className="provider-option">
                                <div className="provider-option-left">
                                    <div className="provider-icon">
                                        <img src={SGVeteriesLogo} alt="" />
                                    </div>
                                </div>
                                <div className="provider-text">$3,000 daily limit</div>
                            </div>
                        </div>
                    </div>

                    <div className="card_footer">
                        <div className="footer_logo_img">
                            <img src={logo} alt="" />
                        </div>
                        <span>Secured by </span>
                        <span className="footer_logo">Ahrvo</span>
                    </div>
                </div>
            </div>
        </>
    );
};
