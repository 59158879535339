import React, { useState } from "react";
import msgIcon from "../../assets/images/msgIcon.svg";
import logo from "../../assets/images/Ahrvo_Network_Logo.png";
import tickIcon from "../../assets/images/tick.svg";
import errorIcon from "../../assets/images/error.svg";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import { Form, Spinner, Alert } from "reactstrap";

export const OTPVerification = () => {
  const [code, setCode] = useState("");
  const [errorVal, setErrorVal] = useState(false);
  const [otpSuccess, setOtpSuccess] = useState(false);
  const [otpFailed, setOtpFailed] = useState(false);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const finalOTP = 123456;

  const handleChange = (code) => {
    setCode(code);
    if (code.length == 6) {
      if (code == finalOTP) {
        setErrorVal("");
        setOtpFailed(false);
        console.log("Success");
        setOtpSuccess(true);
        setTimeout(() => {
          history.push(`${process.env.PUBLIC_URL}/crypto`);
        }, 2000);
      } else {
        setErrorVal(true);
        setOtpSuccess(true);
        setOtpFailed(true);
        // localStorage.removeItem("accessToken", "token");

        console.log(otpFailed);
      }
    } else {
      console.log("Failed");
    }
  };

  const closeOtpFailed = () => {
    localStorage.removeItem("accessToken", "token");
    setTimeout(() => {
      // window.history.pushState(
      //   `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
      //   "auth-login",
      //   `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
      // );
      window.location.reload();
    });
    history.push(`${process.env.PUBLIC_URL}/auth-login`);
  };

  setTimeout(() => {
    setLoading(false)
  }, 1000);

  return (
    <>
      <div className="page-layout">
        <div className="ahrvo-card">
          {loading ? (
            <div className="loader-box">
              <Spinner size="md" className="" color="dark" />
            </div>
          ) : (
            <div className="otp-verification-box">
              <div className="header_logo">
                <img src={msgIcon} alt="" />
              </div>
              <div className="verification-msg">
                {console.log(otpFailed, "otpFailed")}
                {console.log(otpSuccess, "otpSuccess")}

                {!otpSuccess
                  ? " Please enter the code sent to"
                  : !otpFailed
                  ? "You have successfully authenticated your account. Please check your email for the next steps. "
                  : "Your account authentication was unsuccessful."}

                <br />
                <div>ahrvo@info.com</div>
              </div>
              {!otpSuccess ? (
                <OtpInput
                  value={code}
                  onChange={handleChange}
                  numInputs={6}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  containerStyle="otp-form-group"
                  renderInput={(props) => <input {...props} />}
                />
              ) : !otpFailed ? (
                <div className="d-flex justify-content-center my-4">
                  <img src={tickIcon} alt="" />
                </div>
              ) : (
                <div className="d-flex justify-content-center my-4">
                  <img width={"50px"} src={errorIcon} alt="" />
                </div>
              )}
            </div>
          )}
          <div className="error_msg" onClick={closeOtpFailed}>
            {errorVal ? "Please click this link to resend OTP " : ""}
          </div>
          <div className="card_footer">
            <div className="footer_logo_img">
              <img src={logo} alt="" />
            </div>
            <span>Secured by </span>
            <span className="footer_logo">Ahrvo</span>
          </div>
        </div>
      </div>
    </>
  );
};
